import { MouseEvent, forwardRef, useState } from 'react'
import { useLocalTranslation } from '../../hooks'
import { Trans } from 'react-i18next'

export const Preferences = forwardRef<HTMLButtonElement>((_, ref) => {
  const t = useLocalTranslation()
  const [activeElementId, setActiveElementId] = useState<string | undefined>(undefined)
  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    const elementId = event.currentTarget.dataset['accordionTarget']
    if (elementId === activeElementId) {
      setActiveElementId(undefined)
    } else {
      setActiveElementId(elementId)
    }
  }

  return (
    <div
      id='accordion-flush'
      data-accordion='collapse'
      data-active-classnames='bg-white dark:bg-gray-900 text-gray-900 dark:text-white'
      data-inactive-classnames='text-gray-500 dark:text-gray-400'
      className='border-b border-gray-200'
    >
      <h2 id='accordion-flush-heading-1'>
        <button
          type='button'
          className='flex items-center justify-between w-full py-5 font-medium text-left text-gray-500'
          data-accordion-target='#accordion-flush-body-1'
          aria-expanded='true'
          aria-controls='accordion-flush-body-1'
          onClick={onClick}
          ref={ref}
        >
          <span className='text-blue-500 font-normal'>{t('cookieConsent.preferenceSectionHeading')}</span>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6'
          >
            {activeElementId ? (
              <path strokeLinecap='round' strokeLinejoin='round' d='M18 12H6' />
            ) : (
              <path strokeLinecap='round' strokeLinejoin='round' d='M12 6v12m6-6H6' />
            )}
          </svg>
        </button>
      </h2>
      <div
        id='accordion-flush-body-1'
        className={activeElementId === '#accordion-flush-body-1' ? '' : 'hidden'}
        aria-labelledby='accordion-flush-heading-1'
      >
        <div className='py-5'>
          <h3 className='font-semibold text-sm mb-5'>{t('cookieConsent.essentialCookieSubheading')}</h3>
          <Trans i18nKey='cookieConsent.essentialCookieMessage'>
            <p className='mb-2 text-sm text-gray-500 dark:text-gray-400'></p>
            <p className='mb-2 text-sm text-gray-500 dark:text-gray-400'></p>
          </Trans>
        </div>
      </div>
    </div>
  )
})
