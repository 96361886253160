import { HttpStatusCode } from 'axios'
import { ErrorComponenProps, ErrorComponent } from '..'
import { useLocalTranslation } from '../../../hooks'

export const NotFound = ({ primaryLink }: Pick<ErrorComponenProps, 'primaryLink'>) => {
  const t = useLocalTranslation()
  return (
    <ErrorComponent
      statusCode={HttpStatusCode.NotFound}
      heading={t('pageNotFound')}
      message={t('sorryWeCouldntFindThePageYoureLookingFor')}
      primaryLink={primaryLink}
    />
  )
}
