import { useLoaderData } from 'react-router-dom'
import { PageHeading } from '../../../components/page-heading'
import { SectionHeadingWithNav } from '../../../components/section-heading-with-nav'
import { ApiResponse } from '../../../api'
import { ProductList } from '../../../components/product-list'
import { Product } from '../../../types'
import { useDocumentTitle, useLocalTranslation } from '../../../hooks'

export function Products() {
  const loaderData = useLoaderData() as ApiResponse<Array<Product>>
  const t = useLocalTranslation()

  useDocumentTitle(`${t('productConfigurations')} - ${t('administration')}`)

  return (
    <>
      <PageHeading translationText='administration' />
      <SectionHeadingWithNav route='administration' />
      <ProductList products={loaderData?.data} />
    </>
  )
}
