import { ColumnDef, ColumnSort, SortingState, Updater } from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import { TableFooter } from './footer'
import { useQuery } from '@tanstack/react-query'
import { useRequest } from '../../hooks/use-request'
import { SuccessBanner } from '../success-banner'
import { useLocation } from 'react-router-dom'
import { NoResultsComponent } from './no-results-component'
import { TableSkeleton } from './skeleton'
import { LocationStateProps } from '../../routes/types'

import { Table } from './component'
import { NavigateButton, NavigateButtonProps } from '../buttons/navigate-button'
import { Filter } from './filter'
import { useLocalTranslation } from '../../hooks'
import { AxiosError } from 'axios'
import { ErrorBanner } from '../error-banner'
import classNames from 'classnames'

export interface SummaryTableProps<T, V> {
  columns: ColumnDef<T, V>[]
  endpoint: string
  defaultColumnSort: ColumnSort
  refreshTable?: boolean
  navigationButtonProps?: NavigateButtonProps
  showGlobalFilter?: boolean
  filterPlaceholderText?: string
}

export interface TableResults<T> {
  results: T[]
  currentPage: number
  totalPages: number
  totalResults: number
}

interface PageState {
  sorting: SortingState
  filter: string
  page: number
}

const SERVER_INVALID_PAGE_ERROR = 'Invalid page.'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function SummaryTable<T, V = any>({
  columns,
  defaultColumnSort,
  endpoint,
  refreshTable,
  navigationButtonProps,
  showGlobalFilter,
  filterPlaceholderText,
}: SummaryTableProps<T, V>) {
  const location = useLocation()
  const t = useLocalTranslation()
  const [pageState, setPageState] = useState<PageState>({
    sorting: [defaultColumnSort],
    filter: '',
    page: 1,
  })
  const { client } = useRequest()

  const {
    data: tableData,
    isLoading,
    refetch,
    isError,
    error,
  } = useQuery<TableResults<T>>(
    [endpoint, pageState],
    async () => {
      const queryString = (Object.keys(pageState) as Array<keyof PageState>)
        .filter((key: keyof PageState) => !!pageState[key]) // Filter out empty page states
        .map((key: keyof PageState) => {
          // The way React table wants the sorting value and the way our backend wants are bit different, hence the double handling of the sorting state
          if (key === 'sorting') {
            return `ordering=${encodeURIComponent(`${pageState.sorting[0].desc ? '-' : ''}${pageState.sorting[0].id}`)}`
          }
          return `${key}=${encodeURIComponent(`${pageState[key]}`)}`
        })
        .join('&')
      return client.get(`${endpoint}?${queryString}`).then((response) => response.data)
    },
    {
      keepPreviousData: true,
    },
  )

  const isInvalidPageError =
    isError && error instanceof AxiosError && error.response?.data?.['detail'] === SERVER_INVALID_PAGE_ERROR
  if (isInvalidPageError) {
    /**
     * If there is a invalid page error, we retry the query by setting the page to 1.
     * This can happen when a user is on a page greater than the number of results in the queryset.
     * For instance, user is on page 2, and searches for a item that is only available in page 1.
     * In this case, in the backend the query will request page number 2 but the generated queryset will have just 1 page.
     * The django rest framework pagination logic raises a InvalidPage error in this case. This is the out of box and logically
     * correct handling of the scenario. However, the availability of search/filtering functionality in the client means that
     * a user may end up in this scenarios and this code helps us to handle it gracefully.
     **/
    setPageState({ ...pageState, page: 1 })
  }

  useEffect(() => {
    // Refetch table data
    refreshTable && refetch()
  }, [refreshTable])

  if (isLoading) {
    return <TableSkeleton />
  }

  return (
    <>
      {location.state && (location.state as LocationStateProps).created && (
        <SuccessBanner
          heading={(location.state as LocationStateProps).heading ?? t('success')}
          messages={location.state.messages}
        />
      )}
      {isError && !isInvalidPageError && (
        <ErrorBanner errors={{ 'formElement.table': ['errorMessage.tableDataError'] }} />
      )}
      <div
        className={classNames(
          'flex items-center gap-x-6',
          { 'justify-between': showGlobalFilter },
          { 'justify-end': !showGlobalFilter },
        )}
      >
        {showGlobalFilter && (
          <Filter
            filter={pageState.filter}
            setFilter={(filter: string) => setPageState({ ...pageState, filter })}
            filterPlaceholderText={filterPlaceholderText ? t(filterPlaceholderText) ?? '' : ''}
          />
        )}
        {navigationButtonProps && <NavigateButton {...navigationButtonProps} />}
      </div>
      <div className='mt-8 flow-root'>
        <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
              {!isLoading && tableData?.results && !tableData.results.length ? (
                <NoResultsComponent message={t('noRecordsFound')} />
              ) : (
                <Table
                  data={tableData?.results ?? []}
                  columns={columns}
                  sorting={pageState.sorting}
                  onSortingChange={(value: Updater<SortingState>) =>
                    setPageState({
                      ...pageState,
                      sorting: typeof value === 'function' ? value(pageState.sorting) : value,
                    })
                  }
                  pageIndex={pageState.page}
                />
              )}
            </div>
          </div>
        </div>
        {!!tableData?.totalPages && tableData?.totalPages > 1 && (
          <TableFooter
            page={pageState.page}
            setPage={(page: number) => setPageState({ ...pageState, page })}
            totalPages={tableData?.totalPages ?? 10}
          />
        )}
      </div>
    </>
  )
}
