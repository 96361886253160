import { missingEvidenceId, RadiationSafetyLessonEvidenceItem } from './report-types'
import { useLocalTranslation } from '../../../hooks'
import { ReportPageCount } from './report-page-count'
import { PreviousNextNavigation } from './previous-next-navigation'
import { UserNameAndEmail } from './user-name-and-email'
import { useParams } from 'react-router-dom'
import { useMemo, useState } from 'react'
import { useRequest } from '../../../hooks/use-request'
import { useQuery } from '@tanstack/react-query'
import { DoseComparisonTable } from './dose-comparison-table'
import { EvidenceImage } from './evidence-image'

type RadiationSafetyLessonReportProps = {
  evidenceArray: Array<RadiationSafetyLessonEvidenceItem>
}

interface EvidenceImageUrl {
  id: number
  url: string
}

// We always show a full report if any evidence is available for a lesson session. This function ensures that a full set
// of data is available for rendering (by adding dummy entries for missing evidence).
function padIncompleteEvidenceArray(
  evidenceArray: Array<RadiationSafetyLessonEvidenceItem>,
): Array<RadiationSafetyLessonEvidenceItem> {
  if (!evidenceArray || evidenceArray.length === 0) {
    return evidenceArray
  }

  const firstItem = evidenceArray[0]
  const numberOfLessons = firstItem.evidenceData.lesson.count
  const numberOfPartsPerLesson = 2

  // Sort array to ensure evidence is in correct order
  evidenceArray.sort((a, b) => {
    if (a.evidenceData.lesson.index === b.evidenceData.lesson.index) {
      return a.evidenceData.lesson.partIndex - b.evidenceData.lesson.partIndex
    }
    return a.evidenceData.lesson.index - b.evidenceData.lesson.index
  })

  // create output array by using input array where data is available and inserting dummy data to replace any missing evidence
  let currentInputArrayIndex = 0
  let currentOutputArrayIndex = 0
  const outputArray = new Array<RadiationSafetyLessonEvidenceItem>(numberOfLessons * numberOfPartsPerLesson)
  for (let indexLessons = 0; indexLessons < numberOfLessons; indexLessons++) {
    for (let indexParts = 0; indexParts < numberOfPartsPerLesson; indexParts++) {
      const currentInputEvidence = evidenceArray[currentInputArrayIndex]
      if (
        currentInputEvidence !== undefined &&
        currentInputEvidence.evidenceData.lesson.index === indexLessons &&
        currentInputEvidence.evidenceData.lesson.partIndex === indexParts
      ) {
        // use input evidence where we have it
        outputArray[currentOutputArrayIndex] = currentInputEvidence
        currentInputArrayIndex++
      } else {
        // Replace missing evidence with dummy data
        outputArray[currentOutputArrayIndex] = {
          id: missingEvidenceId,
          image: '',
          practiceActivity: firstItem.practiceActivity,
          userName: firstItem.userName,
          userEmail: firstItem.userEmail,
          evidenceData: {
            meta: {
              type: firstItem.evidenceData.meta?.type ?? 'rs_lesson_part',
              version: firstItem.evidenceData.meta?.version ?? 1,
            },
            lesson: {
              id: 'missingEvidence',
              count: firstItem.evidenceData.lesson.count,
              index: indexLessons,
              partId: 'missingEvidence',
              doseTime: 0,
              partIndex: indexParts,
              doses: {
                eye: 0,
                chest: 0,
                hip: 0,
              },
            },
          },
        }
      }
      currentOutputArrayIndex++
    }
  }

  return outputArray
}

export function RadiationSafetyLessonReport({ evidenceArray }: RadiationSafetyLessonReportProps) {
  const t = useLocalTranslation()
  const { reportId } = useParams()
  const { client } = useRequest()

  const [currentPageIndex, setCurrentPageIndex] = useState(0)

  const processedEvidenceArray = useMemo(() => padIncompleteEvidenceArray(evidenceArray), [evidenceArray])

  // Evidence array from the server is an evidence per lesson part. We display the two parts of a lesson per page.
  const evidenceSummary = processedEvidenceArray?.[0] ?? {}
  const currentPartAEvidenceIndex = currentPageIndex * 2
  const currentPartBEvidenceIndex = currentPageIndex * 2 + 1
  const evidenceA = processedEvidenceArray?.[currentPartAEvidenceIndex]?.evidenceData ?? {}
  const evidenceB = processedEvidenceArray?.[currentPartBEvidenceIndex]?.evidenceData ?? {}
  const totalPages = processedEvidenceArray?.[0]?.evidenceData.lesson.count ?? 0
  const evidenceAId = processedEvidenceArray?.[currentPartAEvidenceIndex]?.id
  const evidenceBId = processedEvidenceArray?.[currentPartBEvidenceIndex]?.id

  const {
    data: imageUrls,
    isError,
    isLoading,
  } = useQuery<EvidenceImageUrl[]>({
    queryKey: [reportId, processedEvidenceArray, currentPageIndex],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const firstEvidenceId = processedEvidenceArray?.[currentPartAEvidenceIndex]?.id
      const secondEvidenceId = processedEvidenceArray?.[currentPartBEvidenceIndex]?.id

      // -1 means to image in evidence. The server will not return -1, but we use it locally to represent missing evidence
      if (firstEvidenceId == missingEvidenceId && secondEvidenceId == missingEvidenceId) {
        return []
      }

      let queryUrl = `/api/v1/report/${reportId}/image?`
      if (firstEvidenceId != missingEvidenceId) {
        queryUrl += `id=${firstEvidenceId}`
      }
      if (firstEvidenceId != missingEvidenceId && secondEvidenceId != missingEvidenceId) {
        queryUrl += '&'
      }
      if (secondEvidenceId != missingEvidenceId) {
        queryUrl += `id=${secondEvidenceId}`
      }

      const response = await client.get(queryUrl)
      return response.data
    },
  })

  function onClickPrevious() {
    if (currentPageIndex > 0) {
      setCurrentPageIndex(currentPageIndex - 1)
    }
  }
  function onClickNext() {
    if (currentPageIndex < totalPages - 1) {
      setCurrentPageIndex(currentPageIndex + 1)
    }
  }
  function urlForEvidence(evidenceId: number) {
    return imageUrls?.find((image) => image.id === evidenceId)?.url ?? ''
  }

  return (
    <>
      <div className='grid grid-cols-2 md:grid-cols-4 gap-6 grid-flow-row'>
        <h4 className='col-span-2 sm:col-span-1 md:col-span-2 font-bold text-2xl'>
          {t('lessonReport.' + (evidenceAId != missingEvidenceId ? evidenceA.lesson.id : evidenceB.lesson.id))}
        </h4>
        <UserNameAndEmail
          userEmail={evidenceSummary.userEmail}
          userName={evidenceSummary.userName}
          extraStyle='col-span-2 sm:col-span-1 md:col-span-2 sm:justify-self-end'
        />
        <div className='grid grid-cols-1 col-span-2'>
          <h4 className='font-bold text-lg justify-self-center'>{t('lessonReport.' + evidenceA.lesson.partId)}</h4>
          <EvidenceImage
            evidenceId={evidenceAId}
            src={urlForEvidence(evidenceAId)}
            altText={t('lessonReport.' + evidenceA.lesson.partId)}
            missingText={t('missingEvidenceImage')}
          />
        </div>
        <div className='grid grid-cols-1 col-span-2'>
          <h4 className='font-bold text-lg justify-self-center'>{t('lessonReport.' + evidenceB.lesson.partId)}</h4>
          <EvidenceImage
            evidenceId={evidenceBId}
            src={urlForEvidence(evidenceBId)}
            altText={t('lessonReport.' + evidenceB.lesson.partId)}
            missingText={t('missingEvidenceImage')}
          />
        </div>
        <div className='col-span-2 md:col-span-4'>
          <DoseComparisonTable
            dosesA={evidenceA.lesson.doses}
            dosesATime={evidenceA.lesson.doseTime}
            dosesB={evidenceB.lesson.doses}
            dosesBTime={evidenceB.lesson.doseTime}
          />
        </div>
        <div className='md:col-start-2 col-span-2'>
          {totalPages > 1 && (
            <PreviousNextNavigation
              className='grid grid-flow-col gap-2 justify-center'
              isFirstPage={currentPageIndex === 0}
              isLastPage={currentPageIndex === totalPages - 1}
              onClickPrevious={onClickPrevious}
              onClickNext={onClickNext}
            />
          )}
        </div>
        <div className='md:col-start-2 col-span-2 justify-center'>
          <ReportPageCount
            localizationId={'report.pageNumber'}
            currentPageIndex={currentPageIndex}
            totalPages={totalPages}
            center={true}
          />
        </div>
      </div>
    </>
  )
}
