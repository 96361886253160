import { useLocalTranslation } from '../../../hooks'

export function InlineFieldError({
  errorMessages,
  errorPrefix,
}: {
  errorMessages: Array<string>
  errorPrefix?: string
}) {
  const t = useLocalTranslation()
  const msgPrefix = errorPrefix ?? ''

  return (
    <ul role='list' className='list-disc space-y-1 pl-5'>
      {errorMessages?.map((errorMessage, index) => (
        <li key={index} className='text-sm leading-6 text-red-800'>
          {t([msgPrefix + errorMessage, errorMessage])}
        </li>
      ))}
    </ul>
  )
}
