import { PageHeading } from '../../../../../../components/page-heading'
import { ProductConfigurationDetailsForm } from '../../../product-configuration-details'

export function UpdateProductConfiguration() {
  return (
    <>
      <PageHeading translationText='editConfiguration' />
      <ProductConfigurationDetailsForm action='save' />
    </>
  )
}
