import { useLocalTranslation } from '../../../hooks'

interface LtiLaunchLoginMessageProps {
  loginWithRedirect: () => Promise<void>
}
export function LtiLaunchLoginMessage({ loginWithRedirect }: LtiLaunchLoginMessageProps) {
  const t = useLocalTranslation()
  return (
    <>
      <p className='my-6 text-base leading-7 text-gray-600'>{t('launch.loginMessage')}</p>
      <button
        type='button'
        className='flex rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-border-600 bg-blue-600 hover:bg-blue-500'
        onClick={() => loginWithRedirect()}
      >
        {t('action.login')}
      </button>
    </>
  )
}
