import { Trans } from 'react-i18next'
import { ConfirmationModal } from '../../../../../components/confirmation-modal'
import { useLocalTranslation } from '../../../../../hooks'
import { ConfirmationModalLoading } from '../../../../../components/confirmation-modal/loading'
import { UserSummary } from '../../../../../auth/types'
import { SetStateAction } from 'react'

interface DeleteUserModalProps {
  userForDeletion: Pick<UserSummary, 'id' | 'fullName'> | null
  deleteUser: () => void
  setUserForDeletion: (value: SetStateAction<Pick<UserSummary, 'id' | 'fullName'> | null>) => void
}

export const DeleteUserModal = ({ userForDeletion, deleteUser, setUserForDeletion }: DeleteUserModalProps) => {
  const t = useLocalTranslation()
  return (
    <ConfirmationModal
      title={t('deleteUserModal.title')}
      body={
        userForDeletion?.fullName ? (
          <Trans i18nKey='deleteUserModal.message'>
            Are you sure you want to delete account for {{ username: userForDeletion?.fullName.trimEnd() }}? All of
            their data will be permanently removed. This action cannot be undone.
          </Trans>
        ) : (
          <ConfirmationModalLoading />
        )
      }
      open={!!userForDeletion?.id}
      onConfirm={deleteUser}
      onCancel={() => setUserForDeletion(null)}
      confirmActionLabel={t('deleteUserModal.confirmActionLabel')}
    />
  )
}
