import { FileValidator, MimeTypes } from './types'

export const DEFAULT_FILE_EXTENSIONS: string[] = [...Object.keys(MimeTypes).map((ext) => ext.toLowerCase())]
export const DEFAULT_MIME_TYPES: string[] = Array.from(new Set(Object.values(MimeTypes)))
export const DEFAULT_ALLOWED_FILE_TYPES: string[] = DEFAULT_MIME_TYPES
export const DEFAULT_ALLOWED_FILE_EXTENSIONS: string[] = DEFAULT_FILE_EXTENSIONS
export const DEFAULT_FILE_NAME_MAX_LENGTH = 255
export const DEFAULT_MAX_FILE_SIZE = 10
export const DEFAULT_INVALID_FILE_NAME_REGEX = [
  // eslint-disable-next-line no-control-regex
  /[<>:"/\\|?*\u0000-\u001F]/g,
  /^(con|prn|aux|nul|com\d|lpt\d)$/i,
]

export const CSV_FILE_UPLOAD_VALIDATOR_PARAMS = {
  extension: ['csv'],
  mimeType: [MimeTypes.CSV],
}

export const JSON_FILE_UPLOAD_VALIDATOR_PARAMS = {
  extension: ['json'],
  mimeType: [MimeTypes.JSON],
}

const splitFileName = (fileName: string): string[] => {
  const fileNameParts = fileName.split('.')
  if (fileNameParts.length === 1) {
    return [fileName, '']
  }
  const extension = fileNameParts.pop() ?? ''
  return [fileNameParts.join(), extension]
}

export const formatBytes = (bytes: number) => {
  if (bytes === 0) return '0 b'
  const k = 1024
  const units = ['b', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  // If b or KB, omit decimal place. Otherwise, round to 2 decimal places
  const decimalPlaces = i < 2 ? 0 : 2
  return parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPlaces)) + ' ' + units[i]
}

export const getFileExtension = (fileName: string) => {
  return splitFileName(fileName).pop()
}

export const nameValidator = (invalidRegex: RegExp[] = DEFAULT_INVALID_FILE_NAME_REGEX): FileValidator => {
  return (file: File) => {
    const name = splitFileName(file.name.toLowerCase())[0]
    if (name === '.' || name === '') {
      return {
        isValid: false,
        error: 'File name is invalid.',
      }
    }

    for (let i = 0; i < invalidRegex.length; i++) {
      if (name.match(invalidRegex[i])) {
        return {
          isValid: false,
          error: 'File name is invalid.',
        }
      }
    }

    return { isValid: true }
  }
}

export const extensionValidator = (allowedExtensions: string[] = DEFAULT_ALLOWED_FILE_EXTENSIONS): FileValidator => {
  return (file: File) => {
    const extension = splitFileName(file.name.toLowerCase()).pop()
    if (!(extension && allowedExtensions.includes(extension))) {
      return {
        isValid: false,
        error: `File extension is not valid. Valid extensions include: ${allowedExtensions.join(', ')}.`,
      }
    }

    return { isValid: true }
  }
}

export const mimeTypeValidator = (fileTypes = DEFAULT_ALLOWED_FILE_TYPES): FileValidator => {
  return (file: File) => {
    if (!fileTypes.includes(file.type)) {
      return {
        isValid: false,
        error: `Invalid file type. Valid file types include: ${fileTypes.join(', ')}.`,
      }
    }

    return { isValid: true }
  }
}

export const nameLengthValidator = (max_length = DEFAULT_FILE_NAME_MAX_LENGTH): FileValidator => {
  return (file: File) => {
    if (file.name.length > max_length) {
      return {
        isValid: false,
        error: 'File name is too long.',
      }
    }

    return { isValid: true }
  }
}

export const fileSizeValidator = (max_size = DEFAULT_MAX_FILE_SIZE): FileValidator => {
  return (file: File) => {
    if (Math.round(file.size / 1024 / 1024) > max_size) {
      return {
        isValid: false,
        error: `File is too large. Max file size is ${max_size}MB`,
      }
    }

    return { isValid: true }
  }
}

interface ValidatorProps {
  name?: RegExp[]
  extension?: string[]
  mimeType?: string[]
  nameLength?: number
  size?: number
}

export const buildDefaultValidators = ({
  name = DEFAULT_INVALID_FILE_NAME_REGEX,
  extension = DEFAULT_ALLOWED_FILE_EXTENSIONS,
  mimeType = DEFAULT_ALLOWED_FILE_TYPES,
  nameLength = DEFAULT_FILE_NAME_MAX_LENGTH,
  size = DEFAULT_MAX_FILE_SIZE,
}: ValidatorProps) => {
  const validators = []
  if (name) {
    validators.push(nameValidator(name))
  }
  if (extension) {
    validators.push(extensionValidator(extension))
  }
  if (mimeType) {
    validators.push(mimeTypeValidator(mimeType))
  }
  if (nameLength) {
    validators.push(nameLengthValidator(nameLength))
  }
  if (size) {
    validators.push(fileSizeValidator(size))
  }

  return validators
}

export const DEFAULT_VALIDATORS: FileValidator[] = buildDefaultValidators({})
