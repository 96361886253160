import classNames from 'classnames'
import { Icon, IconTypes } from '../Icon'
import { useLocalTranslation } from '../../hooks'
import { buttonColourSchemes, ButtonStyle } from './button-styles'

interface ButtonProps extends React.ComponentPropsWithRef<'button'> {
  translationText: string
  iconType?: IconTypes
  iconPlacement?: 'left' | 'right'
  colorScheme?: string
}

export function Button({
  translationText,
  disabled,
  iconType,
  iconPlacement,
  colorScheme = 'blue',
  ...props
}: ButtonProps) {
  const t = useLocalTranslation()
  const buttonStyle: ButtonStyle = buttonColourSchemes[colorScheme]
  const style = !disabled ? buttonStyle.enabledStyle : buttonStyle.disabledStyle

  return (
    <button {...props} className={style} disabled={disabled}>
      <span className='flex leading-relaxed items-center gap-2'>
        {iconType && iconPlacement == 'left' && <Icon type={iconType} color='white' />}
        {t(translationText)}
        {iconType && iconPlacement == 'right' && <Icon type={iconType} color='white' />}
      </span>
    </button>
  )
}
