import { CheckCircleIcon } from '@heroicons/react/20/solid'

export const SuccessBanner = ({ heading, messages }: { heading?: string; messages?: Array<string> }) => {
  return (
    <div className='rounded-md bg-green-50 p-4'>
      <div className='flex'>
        <div className='flex-shrink-0'>
          <CheckCircleIcon className='h-5 w-5 text-green-400' aria-hidden='true' />
        </div>
        <div className='ml-3'>
          {heading && <h3 className='text-base font-medium text-green-800'>{heading}</h3>}
          {messages && (
            <div className='mt-2 text-sm text-green-700'>
              <ul role='list' className='list-disc space-y-1 pl-5'>
                {messages.map((message) => (
                  <li>{message}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
