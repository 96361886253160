import { ChevronRightIcon } from '@heroicons/react/20/solid'

import { Link, To } from 'react-router-dom'
import { useLocalTranslation } from '../../hooks'

export interface CrumbProps {
  index: number
  pathname: To
  name: string
}

export function Crumb({ index, pathname, name }: CrumbProps) {
  const t = useLocalTranslation()

  return (
    <li key={`${index}-${name}`}>
      <div className='flex items-center'>
        {index > 0 && <ChevronRightIcon className='h-5 w-5 mr-4 flex-shrink-0 text-gray-400' aria-hidden='true' />}
        <Link to={pathname} className='text-sm font-medium text-gray-500 hover:text-gray-700'>
          {t(name)}
        </Link>
      </div>
    </li>
  )
}
