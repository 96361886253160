import { Outlet } from 'react-router-dom'
import { usePermission } from '../../auth/permissions'
import { UserPermissions } from '../../auth/types'
import { NotFound } from '../../components/error-component/not-found'

export function AdminSection() {
  const canAccessAdminSection = usePermission(UserPermissions.CLIENT_ACCESS_ADMIN_SECTION)
  if (!canAccessAdminSection) {
    return <NotFound />
  }

  return (
    <>
      <Outlet />
    </>
  )
}
