import { ReactElement } from 'react'
import { ReactComponent as MacIcon } from '../../../../assets/mac.svg'
import { ReactComponent as WindowsIcon } from '../../../../assets/window.svg'
import { downloadFile } from '../../../components/file-upload/download'
import { useLocalTranslation } from '../../../hooks'
import { useRequest } from '../../../hooks/use-request'

export type OperatingSystem = 'windows' | 'mac'

type DownloadLink = {
  id: number
  operatingSystem: OperatingSystem
  version: string
  fileId: string
}

export type DownloadItemProps = {
  id: number
  name: string
  downloads: Array<DownloadLink>
}

const OS_ICON_MAP: Record<OperatingSystem, ReactElement> = {
  windows: <WindowsIcon />,
  mac: <MacIcon />,
}

export const DownloadItem = ({ items }: { items: Array<DownloadItemProps> }) => {
  const { client } = useRequest()
  const t = useLocalTranslation()

  return (
    <>
      {items.map(({ id, name, downloads }) => (
        <div key={id} className='mb-5'>
          <h3 className='font-bold leading-9 text-gray-900 text-lg mb-2'>{t(name)}</h3>
          {downloads && downloads.length ? (
            <ul role='list' className='list-disc space-y-1'>
              {downloads.map(({ id, operatingSystem, version, fileId }: DownloadLink) => (
                <li key={id} className='flex gap-4 items-center mb-2'>
                  <p>{OS_ICON_MAP[operatingSystem]}</p>
                  <p>{t(`os.${operatingSystem}`)}</p>
                  <button
                    type='button'
                    className='text-blue-500 text-base text-left'
                    onClick={() =>
                      downloadFile({
                        client,
                        endpoint: `/api/v1/file/${fileId}/download/sim-installation-file`,
                      })
                    }
                  >
                    {`${t('downloads.linkLabel')} ${version}`}
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p className='font-normal text-gray-500'>{t('downloads.noDownloadsForProduct')}</p>
          )}
        </div>
      ))}
    </>
  )
}
