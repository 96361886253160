import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ReactComponent as QuestionMarkIcon } from '../../../assets/question-mark.svg'
import { Preferences } from './preferences'
import { useMutation } from '@tanstack/react-query'
import { useRequest } from '../../hooks/use-request'
import { useAuth, useUser } from '../../auth'
import { AxiosError, AxiosResponse } from 'axios'
import { ErrorBanner } from '../error-banner'
import { useLocalTranslation } from '../../hooks'
import { Trans } from 'react-i18next'

interface CookieConsentModalProps {
  open?: boolean
  onClose?: () => void
}

interface CookiePreferenceData {
  allowEssentialCookies: boolean
}

export const CookieConsentModal = (props: CookieConsentModalProps) => {
  const [open, setOpen] = useState(!!props.open)
  const { client } = useRequest()
  const [user] = useUser()
  const { logout } = useAuth()
  const t = useLocalTranslation()
  const cookiePreferenceBtn = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (!!props.open !== open) {
      setOpen(!!props.open)
    }
  }, [props.open])

  const closeModal = () => {
    setOpen(false)
    props.onClose?.()
  }

  const mutation = useMutation<AxiosResponse, AxiosError, CookiePreferenceData>(
    (data) => client.put(`/api/v1/user/${user?.id}/cookie-preference`, data),
    {
      onSettled: closeModal,
    },
  )

  const accept = () => mutation.mutate({ allowEssentialCookies: true })

  const deny = () => {
    mutation.mutate(
      { allowEssentialCookies: false },
      {
        onSettled: () => logout(),
      },
    )
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' initialFocus={cookiePreferenceBtn} onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                <div>
                  {mutation.isError && (
                    <ErrorBanner errors={{ 'cookieConsent.errorLabel': ['cookieConsent.errorMessage'] }}></ErrorBanner>
                  )}
                  <div className='mx-auto mb-5 flex h-12 w-12 items-center justify-center rounded-full'>
                    <QuestionMarkIcon aria-hidden='true' />
                  </div>
                  <div className='mt-3 sm:mt-0 sm:text-left'>
                    <Dialog.Title as='h3' className='text-base text-center font-medium leading-6 text-gray-900'>
                      {t('cookieConsent.bannerHeading')}
                    </Dialog.Title>
                    <div className='mt-2'>
                      <Trans i18nKey='cookieConsent.bannerMessage'>
                        <p className='mb-2 text-sm text-gray-400'>
                          This website uses cookies to enhance your browsing experience. These cookies are essential for
                          the proper functioning of the website, enabling features such as page navigation and secure
                          access to specific areas.
                        </p>
                        <p className='mb-2 text-sm text-gray-400'>
                          Please note that if you choose to deny the use of cookies, you will be automatically logged
                          out of the portal. We encourage you to review our{' '}
                          <a
                            className='text-blue-500'
                            href='https://www.virtualmedicalcoaching.com/terms-of-use'
                            target='_blank'
                          >
                            Cookie Policy
                          </a>{' '}
                          to understand more about the cookies used in the portal.
                        </p>
                        <p className='mb-2 text-sm text-gray-400'>
                          To acknowledge that you're aware of our use of essential cookies and to proceed with the best
                          possible experience, please click "Allow all".
                        </p>
                      </Trans>
                    </div>
                  </div>
                  <p className='mt-5 border-b border-gray-200' />
                  <Preferences ref={cookiePreferenceBtn} />
                </div>
                <div className='mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
                  <button
                    type='button'
                    className='inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2'
                    onClick={accept}
                  >
                    {t('cookieConsent.allowAll')}
                  </button>
                  <button
                    type='button'
                    className='mt-3 inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-60 sm:col-start-1 sm:mt-0'
                    onClick={deny}
                  >
                    {t('cookieConsent.denyAll')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
