import { Link } from 'react-router-dom'

export function InProgress() {
  return (
    <>
      <div className='grid place-items-center bg-gray-50 px-6 py-24 sm:py-32 lg:px-8'>
        <div className='text-center'>
          <h1 className='mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl'>Development in progress</h1>
          <p className='mt-6 text-base leading-7 text-gray-600'>Sorry, this page is under construction.</p>
          <div className='mt-10 flex items-center justify-center gap-x-6'>
            <Link
              to='/'
              className='rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
            >
              Go back home
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
