import { Form, useMatches, useParams, useLocation, useActionData } from 'react-router-dom'
import { ApiResponse } from '../../../../../api'
import { FormNestedSection } from '../../../../../components/form-nested-group'
import { ReadOnlyField } from '../../../../../components/form-fields/read-only'
import { UserDetails } from '../../../../../types'
import { useDeleteUser, useLocalTranslation } from '../../../../../hooks'
import { Button } from '../../../../../components/buttons/generic-button'
import { FormWrapper } from '../../../../../components/form-wrapper'
import { DeleteUserModal } from './delete-user-modal'
import { getDeleteUserErrorMessages, getDeleteUserSuccessMessages } from '../../../../../utils'
import { NavigateButton } from '../../../../../components/buttons/navigate-button'
import { SubmitButton } from '../../../../../components/buttons/submit-button'
import { InputField } from '../../../../../components/form-fields/input'

interface UserDetailsFormProps {
  loaderData: ApiResponse<UserDetails>
}

export const UserDetailsForm = ({ loaderData }: UserDetailsFormProps) => {
  const { organisationId } = useParams()
  const actionData = useActionData() as ApiResponse<UserDetails>

  // gets the parent pathname for redirect. using '..' as a parent redirect does not work in cases
  // where there is no router history.
  const matches = useMatches()
  const parentPathname = matches.at(-2)?.pathname

  const location = useLocation()
  const cancelNavigateTo = location.state?.cancelNavigateTo

  const t = useLocalTranslation()
  const {
    deleteUser,
    userForDeletion,
    setUserForDeletion,
    isLoading: isDeleting,
    isSuccess: isDeleteSuccess,
    data: deletedData,
    isError: isDeleteError,
    error: deleteError,
  } = useDeleteUser()

  const deleteSuccessMessages = getDeleteUserSuccessMessages({
    isError: isDeleteError,
    axiosResponse: deletedData,
    axiosError: deleteError,
  })

  return (
    <FormWrapper
      isError={isDeleteError || actionData?.isError}
      error={deleteError ? { 'deleteUserModal.title': getDeleteUserErrorMessages(deleteError) } : actionData?.error}
      isSuccess={deleteSuccessMessages.length > 0 || actionData?.isSuccess}
      successMessage={deleteSuccessMessages.length > 0 ? deleteSuccessMessages : 'User details saved'}
      successHeading={deleteSuccessMessages.length > 0 ? 'deleteUser.successHeading' : 'success'}
      successRedirectTo={isDeleteSuccess ? parentPathname : undefined}
      locationState={{
        created: true,
        heading: t('deleteUser.successHeading'),
        messages:
          deleteSuccessMessages.length > 0
            ? Array.isArray(deleteSuccessMessages)
              ? deleteSuccessMessages
              : [deleteSuccessMessages]
            : undefined,
      }}
    >
      <DeleteUserModal
        userForDeletion={userForDeletion}
        setUserForDeletion={setUserForDeletion}
        deleteUser={deleteUser}
      />
      <Form method='put'>
        <div className='flex justify-end'>
          <Button
            type='button'
            iconType='TrashIcon'
            iconPlacement='left'
            onClick={() =>
              setUserForDeletion({
                id: Number(loaderData.data.id),
                fullName: loaderData.data.fullName,
              })
            }
            translationText='deleteUser.actionLabel'
            disabled={isDeleting}
          />
        </div>
        <FormNestedSection title='userDetails'>
          <input type='hidden' name='organisationId' value={organisationId} />
          <ReadOnlyField title='organisation' value={loaderData?.data?.organisationName} />
          <InputField
            id='firstName'
            defaultValue={loaderData?.data?.firstName}
            title='firstName'
            type='text'
            required
            hasError={!!actionData?.error}
            errorMessages={actionData?.error?.firstName}
          />
          <InputField
            id='lastName'
            defaultValue={loaderData?.data?.lastName}
            title='lastName'
            type='text'
            required
            hasError={!!actionData?.error}
            errorMessages={actionData?.error?.lastName}
          />
          <ReadOnlyField title='email' value={loaderData?.data?.email} />
          <ReadOnlyField title='role' value={t(`userRoles.${loaderData?.data?.roleName}`) ?? ''} />
        </FormNestedSection>
        <div className='flex items-center justify-end gap-x-6 mt-4'>
          <NavigateButton
            translationText='cancel'
            navigateTo={cancelNavigateTo ?? parentPathname ?? '..'}
            color='white'
          />
          <SubmitButton translationText='save' />
        </div>
      </Form>
    </FormWrapper>
  )
}
