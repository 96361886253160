import { PageHeading } from '../../../../components/page-heading'
import { OrganisationDetailsForm } from '../[organisationId]/organisation-details-form'

export function NewOrganisation() {
  return (
    <>
      <PageHeading translationText='newOrganisation' />
      <OrganisationDetailsForm action='create' />
    </>
  )
}
