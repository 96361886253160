import { useLocalTranslation } from '../../../../../hooks'
import { Link, useLocation, useParams } from 'react-router-dom'
import { SummaryTable } from '../../../../../components/table'
import { createColumnHelper } from '@tanstack/react-table'
import { ColumnHeader } from '../../../../../components/table/column-header'
import { Pill } from '../../../../../components/pill'

interface Configuration {
  id: number
  operatingSystem: string
  product: number
  version: string
  file: number
  isActive: boolean
}

export const DownloadSummary = () => {
  const t = useLocalTranslation()
  const { pathname } = useLocation()
  const { productId } = useParams()

  const columnHelper = createColumnHelper<Configuration>()
  const columns = [
    columnHelper.accessor('id', {
      header: () => <ColumnHeader header='id' />,
      cell: (info) => info.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor('version', {
      header: () => <ColumnHeader header='version' />,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('operatingSystem', {
      header: () => <ColumnHeader header='operatingSystem' />,
      cell: (info) => t(info.getValue()),
    }),
    columnHelper.accessor('isActive', {
      header: () => <ColumnHeader header='status' />,
      cell: (info) => <Pill isActive={info.getValue()} />,
    }),
    columnHelper.accessor('id', {
      header: () => <span></span>,
      cell: (info) => (
        <Link to={`${pathname}/${info.getValue()}`} className='text-blue-500'>
          {t('edit')}
        </Link>
      ),
      enableSorting: false,
    }),
  ]

  return (
    <>
      <SummaryTable<Configuration>
        columns={columns}
        defaultColumnSort={{
          id: 'isActive',
          desc: true,
        }}
        endpoint={`/api/v1/product/${productId}/simulation-download`}
        navigationButtonProps={{
          iconType: 'PlusIcon',
          translationText: 'addDownload',
          navigateTo: `new`,
          color: 'blue',
        }}
      />
    </>
  )
}
