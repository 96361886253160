import { useLocalTranslation } from '../../../hooks'
import { Doses } from './report-types'

type DoseComparisonTableProps = {
  dosesA: Doses
  dosesATime: number
  dosesB: Doses
  dosesBTime: number
}

export function DoseComparisonTable(doseComparison: DoseComparisonTableProps) {
  const t = useLocalTranslation()
  const fifteenMinutesInSeconds = 15 * 60
  const doseATo15Minutes = fifteenMinutesInSeconds / doseComparison.dosesATime
  const doseBTo15Minutes = fifteenMinutesInSeconds / doseComparison.dosesBTime

  return (
    <>
      <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
        <table className='min-w-full divide-gray-300'>
          <tbody className='divide-y divide-gray-200 bg-white  text-center'>
            <tr className='divide-x'>
              <td className='p-3 font-bold text-gray-800 bg-gray-50 w-1/6'>{t('lessonReport.dose')}</td>
              <td colSpan={2} className='p-3 font-bold text-gray-800 bg-gray-50 w-4/6'>
                {t('lessonReport.fifteenOfFluro')}
              </td>
              <td className='p-3 font-bold text-gray-800 bg-gray-50 w-1/6'>{t('lessonReport.compare')}</td>
            </tr>
            {(() => {
              const tr: JSX.Element[] = []
              const keyOrder = ['Eye', 'Chest', 'Hip']
              keyOrder.forEach((key) => {
                const doseA = doseComparison.dosesA[key]
                const doseB = doseComparison.dosesB[key]
                let percentageChangeAtoBString = '-'
                const percentageChangeAtoB = ((doseB - doseA) / doseA) * 100
                if (!isNaN(percentageChangeAtoB)) {
                  // We want to show the percentage as a whole number (no decimal places)
                  // If the value will round to zero then do not show the sign (+/-)
                  const percentageIsPositive = percentageChangeAtoB > 0
                  const percentageWillRoundToZero = Math.abs(percentageChangeAtoB) < 0.5
                  percentageChangeAtoBString = percentageWillRoundToZero ? '0' : percentageChangeAtoB.toFixed(0)
                  if (percentageIsPositive && !percentageWillRoundToZero) {
                    percentageChangeAtoBString = '+' + percentageChangeAtoBString
                  }
                  percentageChangeAtoBString += '%'
                }
                const fifteenMinutesDoseA = doseA * doseATo15Minutes
                const fifteenMinutesDoseB = doseB * doseBTo15Minutes
                tr.push(
                  <tr className='divide-x' key={key}>
                    <td className='p-3 text-sm font-bold text-gray-800 bg-gray-50 w-1/6'>
                      {t('lessonReport.' + key.toLowerCase())}
                    </td>
                    <td className='p-3 text-sm text-gray-500 font-normal w-2/6'>
                      {isNaN(fifteenMinutesDoseA) ? '-' : fifteenMinutesDoseA.toFixed(2) + ' µSv'}
                    </td>
                    <td className='p-3 text-sm text-gray-500 font-normal w-2/6'>
                      {isNaN(fifteenMinutesDoseB) ? '-' : fifteenMinutesDoseB.toFixed(2) + ' µSv'}
                    </td>
                    <td className='p-3 text-sm text-gray-500 font-normal w-1/6'>{percentageChangeAtoBString}</td>
                  </tr>,
                )
              })
              return tr
            })()}
          </tbody>
        </table>
      </div>
    </>
  )
}
