/**
 * Tailwind does not allow dynamic classnames, so we are maintaining
 * a map of hexcodes to Tailwind classnames.
 * https://tailwindcss.com/docs/content-configuration#dynamic-class-names
 *
 * NOTE: The hexcodes are maintained in the database.
 */
export const HEX_TO_BG_COLOR_MAP: Record<string, string> = {
  '#EFD1B7': 'bg-[#EFD1B7]',
  '#D19C7C': 'bg-[#D19C7C]',
  '#94603B': 'bg-[#94603B]',
  '#321F11': 'bg-[#321F11]',
}

export const HEX_TO_RING_COLOR_MAP: Record<string, string> = {
  '#EFD1B7': 'ring-[#EFD1B7]',
  '#D19C7C': 'ring-[#D19C7C]',
  '#94603B': 'ring-[#94603B]',
  '#321F11': 'ring-[#321F11]',
}
