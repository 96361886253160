import { createColumnHelper } from '@tanstack/react-table'
import { useLoaderData, useParams } from 'react-router-dom'
import { ApiResponse } from '../../../../../../api'
import { PageHeading } from '../../../../../../components/page-heading'
import { Pill } from '../../../../../../components/pill'
import { SummaryTable } from '../../../../../../components/table'
import { ColumnHeader } from '../../../../../../components/table/column-header'
import { UserDetails } from '../../../../../../types'
import { useLocalTranslation } from '../../../../../../hooks'
import { Licenses } from '../../license'
import { UserDetailsForm } from '../user-details-form'
import { useRequest } from '../../../../../../hooks/use-request'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { ConfirmationModal } from '../../../../../../components/confirmation-modal'
import { Trans } from 'react-i18next'
import { SuccessBanner } from '../../../../../../components/success-banner'
import { ErrorBanner } from '../../../../../../components/error-banner'
import { ConfirmationModalLoading } from '../../../../../../components/confirmation-modal/loading'

export function UserDetailsPage() {
  const [removeLicenseBlock, setRemoveLicenseBlock] = useState<Pick<Licenses, 'id' | 'licenseName'> | null>(null)
  const { userId } = useParams()
  const loaderData = useLoaderData() as ApiResponse<UserDetails>
  const t = useLocalTranslation()
  const { client } = useRequest()
  const deleteLicenseBlockMutation = useMutation(
    (licenseBlockId: number) => client.delete(`/api/v1/user/${userId}/license-block/${licenseBlockId}`),
    {
      onSettled: () => setRemoveLicenseBlock(null),
    },
  )

  const columnHelper = createColumnHelper<Licenses>()
  const columns = [
    columnHelper.accessor('licenseName', {
      header: () => <ColumnHeader header='licenseName' />,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('isActive', {
      header: () => <ColumnHeader header='status' />,
      cell: (info) => <Pill isActive={info.getValue()} />,
    }),
    columnHelper.accessor('expiryDate', {
      header: () => <ColumnHeader header='expires' />,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('id', {
      header: () => <span></span>,
      cell: (info) => {
        return (
          <button onClick={() => setRemoveLicenseBlock({ ...info.row.original })} className='text-blue-500'>
            {t('removeLicense.actionLabel')}
          </button>
        )
      },
      enableSorting: false,
    }),
  ]

  const onRemoveLicense = () => {
    if (removeLicenseBlock?.id) {
      deleteLicenseBlockMutation.mutate(removeLicenseBlock.id)
    }
  }

  return (
    <>
      <ConfirmationModal
        title={t('removeLicenseModal.title')}
        body={
          removeLicenseBlock?.licenseName ? (
            <Trans i18nKey='removeLicenseModal.message'>
              Are you sure you want to remove {{ licenseName: removeLicenseBlock?.licenseName ?? 'license' }} from{' '}
              {{ username: loaderData?.data?.fullName.trimEnd() }}?
            </Trans>
          ) : (
            <ConfirmationModalLoading />
          )
        }
        open={!!removeLicenseBlock?.id}
        onConfirm={onRemoveLicense}
        onCancel={() => setRemoveLicenseBlock(null)}
        confirmActionLabel={t('removeLicenseModal.confirmActionLabel')}
      />
      <PageHeading translationText={`${loaderData?.data?.fullName.trimEnd()}`} />
      <UserDetailsForm loaderData={loaderData} />
      <h3 className='mt-12 font-semibold leading-8 text-gray-900 text-xl'>{t('licensesAllocated')}</h3>
      {deleteLicenseBlockMutation.isSuccess && <SuccessBanner heading={t('removeLicense.successMessage') ?? ''} />}
      {deleteLicenseBlockMutation.isError && (
        <ErrorBanner errors={{ 'removeLicenseModal.title': ['removeLicense.errorMessage'] }} />
      )}
      <SummaryTable<Licenses>
        columns={columns}
        defaultColumnSort={{
          id: 'license_name',
          desc: false,
        }}
        refreshTable={deleteLicenseBlockMutation.isSuccess}
        endpoint={`/api/v1/user/${userId}/license-block`}
      />
    </>
  )
}
