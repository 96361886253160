import { Fragment } from 'react'
import { AnalysisState } from '../../../components/form-fields/lookup'
import { useLocalTranslation } from '../../../hooks'

export function AnalysisStatus({ statusCode }: { statusCode: string }) {
  const t = useLocalTranslation()

  return (
    <Fragment>
      {statusCode == AnalysisState.Accepted && (
        <span className='py-1.5 px-4 font-bold rounded-2xl text-green-700 bg-green-100'>{t('report.accepted')}</span>
      )}
      {statusCode == AnalysisState.Rejected && (
        <span className='py-1.5 px-4 font-bold rounded-2xl text-red-700 bg-red-100'>{t('report.rejected')}</span>
      )}
      {statusCode == AnalysisState.NotAnalysed && (
        <span className='py-1.5 px-4 font-bold rounded-2xl text-gray-700 bg-gray-200'>{t('report.not_analysed')}</span>
      )}
    </Fragment>
  )
}
