import { PageLoader } from '../../../components/loaders/page'
import { useLocalTranslation } from '../../../hooks'

export function LtiLaunchLoading() {
  const t = useLocalTranslation()
  return (
    <div>
      <p>{t('launch.loadingState')}</p>
      <PageLoader />
    </div>
  )
}
