import { AxiosError, AxiosInstance, AxiosResponse, HttpStatusCode } from 'axios'
import { NUM_REQUEST_RETRIES } from '../constants'

export const enum RequestMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

export type DataError = Record<string, Array<string>>

export type ApiResponse<TData, TError = DataError> = {
  isSuccess: boolean
  data: TData
  isError: boolean
  error: TError
}

export const fetchClient = <TData, TError = DataError>(client: AxiosInstance) => {
  const handleSuccess = (response: AxiosResponse): ApiResponse<TData, null> => {
    return {
      isSuccess: true,
      data: response.data,
      isError: false,
      error: null,
    }
  }

  const handleError = (error: unknown): ApiResponse<null, TError> => {
    if (error instanceof AxiosError) {
      if (error.response?.status) {
        if ([HttpStatusCode.BadRequest].includes(error.response?.status)) {
          return {
            isError: true,
            error: error.response?.data,
            isSuccess: false,
            data: null,
          }
        }
        if ([HttpStatusCode.Unauthorized, HttpStatusCode.Forbidden].includes(error.response?.status)) {
          // Throwing a 404 lets us make use of react routers error boundaries and redirects the user
          // to the closest error boundary
          throw new Response('Not Found', { status: 404 })
        }
      }
    }
    throw error
  }

  const getItem = async (path: string) => {
    try {
      const response = await client.get(path)
      if ([HttpStatusCode.Ok].includes(response.status)) {
        return handleSuccess(response)
      }
    } catch (error) {
      return await retryIfUnauthorized(error, () => client.get(path), HttpStatusCode.Ok, 1)
    }
  }

  const postItem = async <TPayload>(path: string, payload: TPayload) => {
    try {
      const response = await client.post(path, payload)
      if ([HttpStatusCode.Created].includes(response.status)) {
        return handleSuccess(response)
      }
    } catch (error) {
      return await retryIfUnauthorized(error, () => client.post(path, payload), HttpStatusCode.Created, 1)
    }
  }

  const putItem = async <TPayload>(path: string, payload: TPayload) => {
    try {
      const response = await client.put(path, payload)
      if ([HttpStatusCode.Ok].includes(response.status)) {
        return handleSuccess(response)
      }
    } catch (error) {
      return await retryIfUnauthorized(error, () => client.put(path, payload), HttpStatusCode.Ok, 1)
    }
  }

  const createOrUpdateItem = async <TPayload>(
    path: string,
    payload: TPayload,
    method: RequestMethod.POST | RequestMethod.PUT = RequestMethod.POST,
  ) => {
    if (method === RequestMethod.PUT) {
      return await putItem(path, payload)
    } else {
      return await postItem(path, payload)
    }
  }

  // Try multiple times in case auth token is currently being refreshed
  // Multiple retries are needed to handle rare scenario which may result in error on first attempt
  // to call Django API on very slow network connections
  const retryIfUnauthorized = async (
    error: unknown,
    handleRetry: () => Promise<AxiosResponse>,
    expectedStatus: HttpStatusCode,
    retryCounter: number,
  ): Promise<ApiResponse<TData | null, TError | null>> => {
    if (retryCounter <= NUM_REQUEST_RETRIES) {
      if (error instanceof AxiosError) {
        if (error.response?.status) {
          if ([HttpStatusCode.Unauthorized].includes(error.response?.status)) {
            try {
              const response = await handleRetry()
              if ([expectedStatus].includes(response.status)) {
                return handleSuccess(response)
              }
            } catch (error) {
              return await retryIfUnauthorized(error, () => handleRetry(), expectedStatus, retryCounter + 1)
            }
          }
        }
      }
      // handle error if it's a different error than 401 Unauthorized
      return handleError(error)
    } else {
      // handle any error, even if it's 401 Unauthorized error, only on last retry attempt
      return handleError(error)
    }
  }

  return {
    getItem,
    postItem,
    putItem,
    createOrUpdateItem,
  }
}
