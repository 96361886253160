import { missingEvidenceId } from './report-types'

type EvidenceImageParams = {
  evidenceId: number | undefined
  src: string | undefined
  altText: string
  missingText: string
}

export function EvidenceImage({ evidenceId, src, altText, missingText }: EvidenceImageParams) {
  return (
    <>
      {evidenceId != missingEvidenceId ? (
        <img className='aspect-square w-full' src={src} alt={`Image of ${altText}`} />
      ) : (
        <div className='aspect-square w-full flex justify-center items-center'>
          <p className='text-3xl text-gray-400'>{missingText}</p>
        </div>
      )}
    </>
  )
}
