import { useLoaderData, useLocation, useNavigation } from 'react-router-dom'
import { ApiResponse } from '../../api'
import { PageHeading } from '../../components/page-heading'
import { SimulationCategoryTile, SimulationCategoryTileWrapper } from '../../components/simulation-category'
import { Product } from '../../types'
import { PageLoader } from '../../components/loaders/page'

export function SimulationProductSelection() {
  const { pathname } = useLocation()
  const loaderData = useLoaderData() as ApiResponse<Array<Product>>
  const { state } = useNavigation()

  return state === 'loading' ? (
    <PageLoader />
  ) : (
    <>
      <PageHeading
        translationText='simulations'
        subheadingTranslationText={loaderData?.data.length ? 'simulationsSelectMsg' : 'simulationsNoneAvailableMessage'}
      />
      <SimulationCategoryTileWrapper>
        {loaderData?.data.map(({ id, ...props }: Product) => (
          <SimulationCategoryTile key={id} url={`${pathname}/${id}`} {...props} imageDir='product' />
        ))}
      </SimulationCategoryTileWrapper>
    </>
  )
}
