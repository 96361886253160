import { createColumnHelper } from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import { Pill } from '../../../components/pill'
import { NavigateButton } from '../../../components/buttons/navigate-button'
import { SectionHeadingWithNav } from '../../../components/section-heading-with-nav'
import { PageHeading } from '../../../components/page-heading'

import { ColumnHeader } from '../../../components/table/column-header'
import { SummaryTable } from '../../../components/table'
import { useDocumentTitle, useLocalTranslation } from '../../../hooks'

export interface OrganisationSummary {
  id: number
  name: string
  isActive: boolean
}

export function OrganisationDashboard() {
  const t = useLocalTranslation()

  useDocumentTitle(`${t('organisations')} - ${t('administration')}`)

  // Define the column configuration for the table
  const columnHelper = createColumnHelper<OrganisationSummary>()
  const columns = [
    columnHelper.accessor('name', {
      header: () => <ColumnHeader header='name' />,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('isActive', {
      header: () => <ColumnHeader header='status' />,
      cell: (info) => <Pill isActive={info.getValue()} />,
      enableSorting: false,
    }),
    columnHelper.accessor('id', {
      header: () => <span></span>,
      cell: (info) => (
        <Link to={`${location.pathname}/${info.getValue()}`} className='text-blue-500'>
          {t('edit')}
        </Link>
      ),
      enableSorting: false,
    }),
  ]

  return (
    <>
      <PageHeading translationText='administration' />
      <SectionHeadingWithNav route='administration' />
      <div className='flex items-center justify-end gap-x-6'>
        <NavigateButton
          iconType='PlusIcon'
          translationText='newOrganisation'
          navigateTo={`${location.pathname}/new`}
          color='blue'
        />
      </div>
      <SummaryTable<OrganisationSummary>
        columns={columns}
        defaultColumnSort={{
          id: 'id',
          desc: true,
        }}
        endpoint='/api/v1/organisation'
        showGlobalFilter
        filterPlaceholderText='organisationSearch'
      />
    </>
  )
}
