import { useLocation } from 'react-router-dom'
import { PracticeViewCategory } from '../../routes/simulation/[productId]/category'
import { SimulationCategoryTile, SimulationCategoryTileWrapper } from '../simulation-category'
import { SimLaunchActions } from '../sim-launch-actions'

interface PracticeViewCategoryProps {
  product: string
  bodyArea?: string
  bodyAreaName?: string
  productProtocol: string
  productId?: string
  categories: PracticeViewCategory[]
}

export function PracticeViewCategoryTiles({
  product,
  bodyArea,
  bodyAreaName,
  productProtocol,
  productId,
  categories,
}: PracticeViewCategoryProps) {
  const { pathname } = useLocation()
  // Prepare the image directory to look for images.
  // If body area is passed, look for the images under the body area sub directory
  const imageDir = bodyArea ? `${product}/${bodyArea}` : product
  const productsWithSimLaunchImage = Object.freeze(['birthing', 'radiationSafety'])
  const launchTileHasImage = productsWithSimLaunchImage.includes(product)

  return (
    <SimulationCategoryTileWrapper>
      {categories.map(({ id, name, displayName, children, simulationConfiguration }: PracticeViewCategory) =>
        children?.length ? (
          <SimulationCategoryTile key={id} {...{ url: `${pathname}/${id}`, name, displayName, imageDir }} />
        ) : (
          <li key={`${id}`}>
            <SimLaunchActions
              name={name}
              displayName={displayName}
              simulationConfiguration={simulationConfiguration}
              productProtocol={productProtocol}
              productId={productId}
              imageDir={launchTileHasImage ? imageDir : undefined}
            />
          </li>
        ),
      )}
    </SimulationCategoryTileWrapper>
  )
}
