import { AxiosError } from 'axios'
import { useEffect, useRef, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { DEFAULT_ALLOWED_FILE_TYPES, DEFAULT_VALIDATORS } from './validators'
import { FileErrorResponse, FileState, FileUploadProps, NewFile, UploadedFile } from './types'
import { useRequest } from '../../hooks/use-request'
import { Title } from '../form-fields/componentry/title'
import { InlineFieldError } from '../form-fields/componentry/error-message'

export function FileUpload({
  fileRequirementsText,
  disabled,
  onFileUploading,
  onFileUploaded,
  onFileUploadFailed,
  fileValidators = DEFAULT_VALIDATORS,
  allowedFileTypes = DEFAULT_ALLOWED_FILE_TYPES,
  endpoint,
  title,
  required,
  hasError,
  errorMessages,
}: FileUploadProps) {
  const [failedFile, setFailedFile] = useState<NewFile | null>(null)
  const { client } = useRequest()

  const fileInputRef = useRef<HTMLInputElement>(null)

  const uploadFile = async (file: NewFile) => {
    const formData = new FormData()
    formData.append('file', file.file)
    const { data } = await client.post<Omit<UploadedFile, 'state'>>(endpoint, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return data
  }

  const addFileMutation = useMutation<Omit<UploadedFile, 'state'>, AxiosError<FileErrorResponse>, NewFile>(uploadFile, {
    onMutate: (newFile: NewFile) => {
      onFileUploading?.(newFile)
    },
    onSuccess: (result: Omit<UploadedFile, 'state'>, sent: NewFile) => {
      onFileUploaded?.(
        {
          ...result,
          state: FileState.UPLOADED,
        },
        sent.id,
      )
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any, newFile: NewFile) => {
      if ('name' in error && error?.name === 'AbortError') {
        // We don't care if the upload was aborted.
        return
      }
      setFailedFile(newFile)
      onFileUploadFailed?.(newFile, error)
    },
  })

  const handleFile = () => {
    const files = fileInputRef.current?.files
    Array.from(files ?? []).forEach((file: File) => {
      const { isValid, error } = validateFile(file)
      const newFile: NewFile = {
        id: `temp_${Math.floor(Math.random() * 1e10)}`, // Temporary file id
        originalName: file.name,
        size: file.size,
        file: file,
        createdTimestamp: new Date().toISOString(),
        state: FileState.UPLOADING,
        abortController: new AbortController(),
      }

      if (isValid && files?.length === 1) {
        addFileMutation.mutate(newFile)
      } else {
        newFile.error = error
        setFailedFile(newFile)
      }
    })
    if (fileInputRef.current) {
      // Reset the value so that a file with the same name is treated as a new file.
      // The drawback of this approach is that the file input will show "No file chosen" after the file is uploaded.
      // This should be fine though because we show the uploaded file underneath the input element.
      fileInputRef.current.value = ''
    }
  }

  const validateFile = (file: File) => {
    for (let i = 0; i < fileValidators.length; i++) {
      const result = fileValidators[i](file)
      if (!result.isValid) {
        return result
      }
    }

    return {
      isValid: true,
    }
  }

  useEffect(() => {
    setFailedFile((failedFile) => {
      if (failedFile) {
        // TODO: Add toast message to display error to the user
        console.warn(`File upload failed for ${failedFile?.originalName}: ${failedFile?.error}`)
      }
      return null
    })
  }, [failedFile])

  const isErrorState =
    (hasError && errorMessages) ||
    (addFileMutation.isError && addFileMutation.error && addFileMutation.error instanceof AxiosError)

  return (
    <div className='sm:col-span-4'>
      <label className='sr-only' htmlFor='file_input'>
        Browse
      </label>
      {title && <Title id='file_input' title={title} htmlFor='file_input' required={required} />}
      <input
        className='block w-full border border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-300 dark:text-gray-400
          file:bg-transparent file:border-0
          file:bg-gray-100 file:mr-4
          file:py-3 file:px-4
          dark:file:bg-gray-300 dark:file:text-gray-700'
        aria-describedby='file_input_help'
        aria-label='File upload'
        id='file_input'
        ref={fileInputRef}
        type='file'
        accept={allowedFileTypes.join(',')}
        multiple={false}
        onChange={handleFile}
        disabled={disabled}
      />
      {fileRequirementsText ?? (
        <p className='mt-1 text-sm text-gray-500 dark:text-gray-300' id='file_input_help'>
          {fileRequirementsText}
        </p>
      )}
      {isErrorState && (
        <InlineFieldError errorMessages={(addFileMutation.error?.response?.data?.['file'] || errorMessages) ?? ['']} />
      )}
    </div>
  )
}
