import { PropsWithChildren, useEffect, useRef } from 'react'
import { To, useNavigate } from 'react-router-dom'
import { DataError } from '../api'
import { LocationStateProps } from '../routes/types'
import { SuccessBanner } from './success-banner'
import { ErrorBanner } from './error-banner'
import { useLocalTranslation } from '../hooks'

interface FormWrapperProps {
  isSuccess?: boolean
  isError?: boolean
  error?: DataError
  successRedirectTo?: To
  successHeading?: string
  successMessage?: string | string[]
  locationState?: LocationStateProps
}

export function FormWrapper({
  successRedirectTo,
  isSuccess,
  isError,
  error,
  successHeading,
  successMessage,
  locationState,
  children,
}: PropsWithChildren<FormWrapperProps>) {
  const formWrapperRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const t = useLocalTranslation()

  const getMessages = () => {
    if (!!locationState?.messages) return locationState?.messages
    return Array.isArray(successMessage) ? successMessage : [successMessage ?? '']
  }

  useEffect(() => {
    if (isSuccess && successRedirectTo) {
      navigate(successRedirectTo, {
        state: {
          ...(locationState ?? {}),
        },
      })
    } else if (formWrapperRef.current && (isError || isSuccess)) {
      formWrapperRef.current.scrollIntoView()
    }
  }, [isSuccess, isSuccess, isError, successRedirectTo, error])

  return (
    <div ref={formWrapperRef} className='container mx-auto p-4 sm:px-6 lg:px-8 sm:p-6 lg:p-8 bg-white grid gap-4'>
      {isError && error ? <ErrorBanner errors={error} /> : null}
      {!successRedirectTo && isSuccess && (
        <SuccessBanner heading={`${t(successHeading ?? 'success')}`} messages={getMessages()} />
      )}
      {children}
    </div>
  )
}
