import { ReactNode } from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'

export const ErrorBoundary = ({ children }: { children: ReactNode }) => (
  <ReactErrorBoundary
    fallbackRender={() => {
      return (
        <div>
          <h1>Something went wrong</h1>
          <p>If the problem persists, please contact your administrator.</p>
        </div>
      )
    }}
  >
    {children}
  </ReactErrorBoundary>
)
