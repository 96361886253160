import { useLocalTranslation } from '../../../hooks'
import { ReportTableProps, RowAttrs } from './report-types'

export function ReportTable({ tableTitle, data }: ReportTableProps) {
  const t = useLocalTranslation()

  const handleRowAttrs = (rowAttrs: RowAttrs) => {
    // exit early and display a dash where values are not present
    if (rowAttrs.value == '' || rowAttrs.value == undefined) return '-'

    switch (rowAttrs.valueType) {
      case 'simpleIntl':
        if (typeof rowAttrs.value == 'string') return t(rowAttrs.value)
        // for development purposes
        console.error('A simpleIntl valueType must have a `value` of string ')
        break
      case 'complexIntl':
        if (Array.isArray(rowAttrs.value)) return t(...rowAttrs.value)
        // for development purposes
        console.error('A complexIntl valueType must have a `value` of array. e.g. `[string, {[key: string]: number}]`')
        break
      case 'number':
      case 'decimal':
      case 'rawString':
      default:
        return String(rowAttrs.value)
    }
  }

  return (
    <>
      <div>
        <h1 className='text-xs uppercase leading-4 text-gray-500'>{t(`report.${tableTitle}`)}</h1>
        <div className='flow-root mt-2'>
          <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {Object.entries(data)?.map(([rowTitle, rowAttrs]) => {
                      return (
                        <tr key={rowTitle}>
                          <td className='p-3 text-sm font-normal text-gray-800 bg-gray-50 w-44'>
                            {t(`report.${rowTitle}`)}
                          </td>
                          <td className='p-3 text-sm text-gray-500 font-normal'>{handleRowAttrs(rowAttrs)}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
