import { RadioGroup } from '@headlessui/react'
import { useQuery } from '@tanstack/react-query'
import { useRequest } from '../../hooks/use-request'
import { useLocalTranslation } from '../../hooks'
import classNames from 'classnames'
import { HEX_TO_BG_COLOR_MAP, HEX_TO_RING_COLOR_MAP } from './color-map'

interface LookupColorFieldOption {
  id: string
  label: string
  code: string
  description: string
}

interface ColorPickerProps {
  label: string
  selectedColorId: number | undefined
  setSelectedColorId?: (colorId: number) => void
}

export const ColorPicker = ({ label, selectedColorId, setSelectedColorId }: ColorPickerProps) => {
  const { client } = useRequest()
  const t = useLocalTranslation()

  const { data } = useQuery({
    queryKey: ['colorPicker'],
    queryFn: async () => {
      const response = client.get<Array<LookupColorFieldOption>>(`/api/v1/lookup?field=skinTone`)
      return (await response).data
    },
  })

  return (
    <RadioGroup value={selectedColorId} onChange={setSelectedColorId}>
      <RadioGroup.Label className='block text-sm font-medium leading-6 text-gray-900'>{t(label)}</RadioGroup.Label>
      <div className='mt-4 flex items-center space-x-3'>
        {data?.map((color) => {
          return (
            <RadioGroup.Option
              key={color.id}
              value={color.id}
              className={({ active, checked }) =>
                classNames(
                  HEX_TO_RING_COLOR_MAP[color.code],
                  active && checked ? 'ring ring-offset-1' : '',
                  !active && checked ? 'ring-2' : '',
                  'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none',
                )
              }
            >
              <RadioGroup.Label as='span' className='sr-only'>
                {t(color.label)}
              </RadioGroup.Label>
              <span
                aria-hidden='true'
                className={classNames(
                  HEX_TO_BG_COLOR_MAP[color.code],
                  'h-8 w-8 rounded-full border border-black border-opacity-10',
                )}
              />
            </RadioGroup.Option>
          )
        })}
      </div>
    </RadioGroup>
  )
}
