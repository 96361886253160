import classNames from 'classnames'
import { Link, NavLinkProps, To } from 'react-router-dom'
import { Icon, IconTypes } from '../Icon'
import { useLocalTranslation } from '../../hooks'
import { blueButton, buttonColourSchemes, lightBlueButton, whiteButton } from './button-styles'

export interface NavigateButtonProps extends Pick<NavLinkProps, 'state'> {
  translationText: string
  navigateTo: To
  color: 'blue' | 'white' | 'light-blue'
  iconType?: IconTypes
  target?: '_blank'
  iconPlacement?: 'left' | 'right'
}

export function NavigateButton({
  iconType,
  translationText,
  navigateTo,
  color,
  target,
  state,
  iconPlacement = 'left',
}: NavigateButtonProps) {
  const t = useLocalTranslation()
  const style = classNames('grid align-center', buttonColourSchemes[color].enabledStyle)

  return (
    <Link to={navigateTo} replace={true} className={style} state={state} target={target}>
      <span className='flex leading-relaxed items-center gap-2'>
        {iconType && iconPlacement == 'left' && <Icon type={iconType} color='white' />}
        {t(translationText)}
        {iconType && iconPlacement == 'right' && <Icon type={iconType} color='white' />}
      </span>
    </Link>
  )
}
