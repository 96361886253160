import { useDocumentTitle, useLocalTranslation } from '../hooks'

interface PageHeadingProps {
  translationText: string
  subheadingTranslationText?: string
  skipTranslation?: boolean
}

export function PageHeading({ translationText, subheadingTranslationText, skipTranslation = false }: PageHeadingProps) {
  const t = useLocalTranslation()

  useDocumentTitle(t(translationText))

  return (
    <>
      <h3 className='font-bold leading-9 text-gray-900 text-3xl'>
        {skipTranslation ? translationText : t(translationText)}
      </h3>
      {subheadingTranslationText && (
        <p className='text-xl leading-7 font-normal text-gray-500'>
          {skipTranslation ? subheadingTranslationText : t(subheadingTranslationText)}
        </p>
      )}
    </>
  )
}
