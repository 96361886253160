import { useState } from 'react'
import { HelpText } from './componentry/helpText'
import { Title } from './componentry/title'
import { useQuery } from '@tanstack/react-query'
import { LookupFieldOptions, LookupFieldOption } from './lookup'
import { useRequest } from '../../hooks/use-request'
import { useLocalTranslation } from '../../hooks'

interface ReadOnlyFieldLookupProps extends React.ComponentPropsWithoutRef<'input'> {
  title: string
  lookupField: string
  lookupId: string
  required?: boolean
  helpText?: string
}

export function ReadOnlyLookupField({ title, helpText, lookupField, lookupId, ...props }: ReadOnlyFieldLookupProps) {
  const t = useLocalTranslation()
  const endpoint = `/api/v1/lookup?field=${lookupField}`
  const { client } = useRequest()
  const [lookupOptions, setLookupOptions] = useState<LookupFieldOptions | []>([])

  useQuery({
    queryKey: [lookupField],
    queryFn: async () => client.get<LookupFieldOptions>(endpoint),
    onSuccess: async ({ data }) => {
      setLookupOptions(data)
    },
  })

  let displayValue = lookupOptions?.find(({ id }: LookupFieldOption) => id == lookupId)?.description
  if (displayValue) {
    // Translate values
    displayValue = Array.isArray(displayValue) ? displayValue.map((value) => t(value)) : (t(displayValue) as string)
  }

  return (
    <div className='sm:col-span-4'>
      <Title title={title} />
      <input
        name={props.id}
        disabled={true}
        type='text'
        className='bg-gray-100 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 sm:text-sm sm:leading-6'
        value={displayValue}
        {...props}
      />
      {helpText && <HelpText helpText={helpText} />}
    </div>
  )
}
