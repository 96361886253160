import { useLocalTranslation } from '../../hooks'

interface ColumnHeaderProps {
  header: string
}

export function ColumnHeader({ header }: ColumnHeaderProps) {
  const t = useLocalTranslation()
  return <span className='uppercase'>{t(header)}</span>
}
