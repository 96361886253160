import { createColumnHelper } from '@tanstack/react-table'
import { Link, useLocation, useParams } from 'react-router-dom'
import { ColumnHeader } from '../../../../../components/table/column-header'
import { SummaryTable } from '../../../../../components/table'
import { useDeleteUser, useDocumentTitle, useLocalTranslation } from '../../../../../hooks'
import { SuccessBanner } from '../../../../../components/success-banner'
import { ErrorBanner } from '../../../../../components/error-banner'
import { UserSummary } from '../../../../../auth/types'
import { DeleteUserModal } from './delete-user-modal'
import { getDeleteUserSuccessMessages, getDeleteUserErrorMessages } from '../../../../../utils'

export function UsersDashboard() {
  const t = useLocalTranslation()
  const location = useLocation()
  const { organisationId } = useParams()

  useDocumentTitle(t('users'))

  const { deleteUser, userForDeletion, setUserForDeletion, isSuccess, data, isError, error } = useDeleteUser()

  // Define the column configuration for the table
  const columnHelper = createColumnHelper<UserSummary>()
  const columns = [
    columnHelper.accessor('fullName', {
      header: () => <ColumnHeader header='name' />,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('email', {
      header: () => <ColumnHeader header='email' />,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('licenseNames', {
      header: () => <ColumnHeader header='licensesName' />,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('userRole', {
      header: () => <ColumnHeader header='role' />,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('id', {
      header: () => <span></span>,
      cell: (info) => (
        <Link to={`${info.cell.getValue()}`} className='text-blue-500'>
          {t('edit')}
        </Link>
      ),
      enableSorting: false,
    }),
    columnHelper.accessor('id', {
      header: () => <span></span>,
      cell: (info) => {
        return (
          <button onClick={() => setUserForDeletion({ ...info.row.original })} className='text-blue-500'>
            {t('delete')}
          </button>
        )
      },
      enableSorting: false,
    }),
  ]

  const deleteSuccessMessages = getDeleteUserSuccessMessages({
    isError: isError,
    axiosResponse: data,
    axiosError: error,
  })

  return (
    <>
      <DeleteUserModal
        userForDeletion={userForDeletion}
        setUserForDeletion={setUserForDeletion}
        deleteUser={deleteUser}
      />
      {deleteSuccessMessages.length > 0 && (
        <SuccessBanner
          heading={t('deleteUser.successHeading') ?? ''}
          messages={Array.isArray(deleteSuccessMessages) ? deleteSuccessMessages : [deleteSuccessMessages]}
        />
      )}
      {isError && <ErrorBanner errors={{ 'deleteUserModal.title': getDeleteUserErrorMessages(error) }} />}
      <SummaryTable<UserSummary>
        columns={columns}
        defaultColumnSort={{
          id: 'id',
          desc: true,
        }}
        navigationButtonProps={{
          iconType: 'PlusIcon',
          translationText: 'bulkAddUsers',
          navigateTo: `${location.pathname}/new`,
          color: 'blue',
        }}
        endpoint={`/api/v1/organisation/${organisationId}/user`}
        showGlobalFilter
        filterPlaceholderText='searchNameOrEmail'
        refreshTable={isSuccess}
      />
    </>
  )
}
