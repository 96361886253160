export const TITLE = 'Virtual Medical Coaching'

export const APP_INFO = {
  logoTextLine1: TITLE,
  logoTextLine2: TITLE,
  altText: TITLE,
  icon: undefined,
}

export const CLIENT_ADDRESS = process.env.REACT_APP_CLIENT_ADDRESS
export const SERVER_ADDRESS = process.env.REACT_APP_SERVER_ADDRESS

export const KEYCLOAK = {
  url: process.env.REACT_APP_KEYCLOAK_ADDRESS,
  clientId: process.env.REACT_APP_KEYCLOAK_FRONTEND_CLIENT_ID,
  realm: process.env.REACT_APP_KEYCLOAK_MAIN_REALM,
  pkceMethod: process.env.REACT_APP_KEYCLOAK_PKCE_METHOD,
}

export const DEFAULT_DEBOUNCE_MS = 300

export const DEFAULT_POLLING_INTERVAL_MS = 1000
export const DEFAULT_SIM_INSTALLATION_CHECK_TIMEOUT_MS = 10000

export const BRIGHTSPACE_HOSTNAME = process.env.REACT_APP_BRIGHTSPACE_HOSTNAME

export const NUM_REQUEST_RETRIES = 3

export const VERSION = process.env.REACT_APP_VERSION
export const GIT_HASH = process.env.REACT_APP_GIT_HASH
