import { useLoaderData, useSearchParams } from 'react-router-dom'
import { ApiResponse } from '../../../api'
import { PageHeading } from '../../../components/page-heading'
import { EvidenceItem, RadiographyEvidenceItem, RadiationSafetyLessonEvidenceItem } from './report-types'
import { NavigateButton } from '../../../components/buttons/navigate-button'
import { RadiographyReport } from './radiography-report'
import { RadiationSafetyLessonReport } from './radsafety-lesson-report'
import { useState } from 'react'

export function Report() {
  const evidenceApiResult = useLoaderData() as ApiResponse<Array<EvidenceItem>>
  const [searchParams] = useSearchParams()
  const isLtiLaunch = !!searchParams.get('launchId')
  // For lti display via Brightspace the page can be reloaded due to redirect during submission of the analysis form.
  // See the router action/loader and element for the analysis route. This causes the params to be lost which means we
  // need to store the lti status in state to avoid losing it.
  const [isLti] = useState(isLtiLaunch)
  const [searchParamsString] = useState(searchParams.toString())

  const evidenceArray = evidenceApiResult?.data
  const firstEvidence = evidenceArray?.[0]
  const simData = firstEvidence?.evidenceData?.sim
  const metaData = firstEvidence?.evidenceData?.meta
  const simDataString = simData
    ? Object.entries(simData)
        .map(([key, value]) => `${key}: ${value}`)
        .join(', ')
    : 'No sim data available'
  const metaDataString = metaData
    ? Object.entries(metaData)
        .map(([key, value]) => `${key}: ${value}`)
        .join(', ')
    : 'No meta data available'

  // Not sure if this is the best way to handle this. But it works for now.
  function renderAppropriateReport() {
    if (firstEvidence?.evidenceData?.meta) {
      switch (firstEvidence.evidenceData.meta.type) {
        case 'rs_lesson_part':
          return <RadiationSafetyLessonReport evidenceArray={evidenceArray as RadiationSafetyLessonEvidenceItem[]} />
        case 'xray_image_details':
          // default behaviour is to treat evidence as radiography evidence.
          break
        default:
          break
      }
    }
    return <RadiographyReport evidence={evidenceArray as RadiographyEvidenceItem[]} isLti={isLti} />
  }

  return (
    <>
      <div className='flex mt-2 mx-1 justify-between gap-y-2'>
        <PageHeading translationText={firstEvidence.practiceActivity} skipTranslation />{' '}
        {isLti && (
          <NavigateButton
            translationText='report.startOver'
            navigateTo={{
              pathname: `${isLti ? '/lti' : '/simulation'}/launch`,
              search: searchParamsString,
            }}
            color='blue'
            iconType='ArrowPathIcon'
          />
        )}
      </div>
      {renderAppropriateReport()}
      <p hidden={true}>{simDataString}</p>
      <p hidden={true}>{metaDataString}</p>
    </>
  )
}
