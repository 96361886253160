import { useEffect } from 'react'
import { canUser } from '../auth/permissions'
import { UserPermissions } from '../auth/types'
import { PageHeading } from '../components/page-heading'
import { BRIGHTSPACE_HOSTNAME } from '../constants'
import { useRequest } from '../hooks/use-request'
import { useMutation } from '@tanstack/react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUser } from '../auth'

export default function BrightspaceUserMismatch() {
  const { client } = useRequest()

  const { state } = useLocation()

  const navigate = useNavigate()

  const [user, isLoading] = useUser()

  const mutation = useMutation(() => client.post('/api/v1/user/brightspace-auth-reset'), {
    onSuccess: () => {
      window.location.pathname = state.from
    },
  })

  useEffect(() => {
    if (!isLoading && (!user || !canUser(user, UserPermissions.CLIENT_ACCESS_ADMIN_SECTION))) {
      navigate('/')
    }
  }, [user, isLoading])

  return (
    <>
      <PageHeading translationText='brightspaceUserMismatch' />
      <p>
        We have detected a mismatch between the user you are logged into the VMC webportal with and the user you are
        logged into Brightspace.
      </p>
      <p>
        You can ignore this error as it will only affect your ability to perform certain actions such as adding new
        users, however the system will periodically redirect you back to this page until the problem is solved.
      </p>
      <div>
        This could be because either:
        <ul className='list-disc list-inside'>
          <li>you are logged into another user in Brightspace </li>
          <li>or because of a misconfiguration between the systems.</li>
        </ul>
      </div>
      <h3 className='font-bold leading-9 text-gray-900 text-xl'>Resolution</h3>
      <div>
        To resolve the issue:
        <ul className='list-decimal list-inside'>
          <li>
            First, ensure you are logged out of Brightspace using this button:{' '}
            <button
              type='button'
              className='flex rounded-md px-3 py-2 ml-4 m-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-border-600 bg-blue-600 hover:bg-blue-500'
            >
              <a href={`https://${BRIGHTSPACE_HOSTNAME}/d2l/logout`} target='_blank' rel='noreferrer noopener'>
                Brightspace logout
              </a>
            </button>
          </li>
          <li>
            Then, use the following button to reset the authentication and login again{' '}
            <button
              type='button'
              className='flex rounded-md px-3 py-2 ml-4 m-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-border-600 bg-blue-600 hover:bg-blue-500'
              onClick={() => mutation.mutate()}
            >
              Reset Brightspace session
            </button>
          </li>
        </ul>
      </div>
      <p>
        If the problem persists, then it's a misconfiguration between the systems and will need to be resolved by
        support.
      </p>
    </>
  )
}
