import { useLocalTranslation } from '../../hooks'
import { InlineFieldError } from './componentry/error-message'
import { HelpText } from './componentry/helpText'
import { Title } from './componentry/title'

export interface InputFieldProps extends React.ComponentPropsWithRef<'input'> {
  title: string
  helpText?: string
  hasError?: boolean
  errorMessages?: Array<string>
}

export function InputField({ title, helpText, errorMessages, hasError, required, ...props }: InputFieldProps) {
  const t = useLocalTranslation()
  return (
    <div className='sm:col-span-4'>
      <Title id={props.id} title={title} htmlFor={props.id} required={required} />
      <input
        type={props.type}
        name={props.id}
        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
        {...props}
      />
      {helpText && <HelpText helpText={t(helpText)} />}
      {hasError && errorMessages && <InlineFieldError errorMessages={errorMessages} />}
    </div>
  )
}
