import { PageHeading } from '../../../../../../components/page-heading'
import { DownloadDetailsForm } from '../download-details-form'

export function UpdateDownload() {
  return (
    <>
      <PageHeading translationText='editDownload' />
      <DownloadDetailsForm action='save' />
    </>
  )
}
