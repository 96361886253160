import { useQuery } from '@tanstack/react-query'
import { InlineFieldError } from './componentry/error-message'
import { HelpText } from './componentry/helpText'
import { Title } from './componentry/title'
import { useRequest } from '../../hooks/use-request'
import { useState } from 'react'
import { translateAndJoinList } from '../../utils'
import { useLocalTranslation } from '../../hooks'

// TODO: Fix this. id is not a string, at runtime it is a number.
export interface LookupFieldOption {
  id: string
  description: string | string[]
  name: string
}

// LookupFieldOptions for Analysis Status
export enum AnalysisState {
  NotAnalysed = '22001',
  Accepted = '22002',
  Rejected = '22003',
}

export type LookupFieldOptions = LookupFieldOption[]

interface LookupFieldProps extends Omit<React.ComponentPropsWithoutRef<'select'>, 'defaultValue'> {
  lookupField: string
  title: string
  defaultValue?: LookupFieldOption
  helpText?: string
  hasError?: boolean
  errorMessages?: Array<string>
  endpoint?: string
  emptyLabel?: string
  onChangeCallback?: (event: React.ChangeEvent<HTMLSelectElement>, options?: LookupFieldOptions) => void
  idsToHide?: string[]
  // The display text for the lookup field should be the description from the server by default to match existing behavior.
  // If this is set to true, the name will be used to look up a client side translation instead.
  useClientTranslations?: boolean
  // If useNameTranslationAsDescription is true, this prefix will be added to the name to look up the translation.
  // For example. accepted/rejected are under the report object in the json. So this would need to be set to 'report.'
  nameTranslationPrefix?: string
  errorTranslationPrefix?: string
}

export function LookupField({
  lookupField,
  title,
  defaultValue,
  helpText,
  hasError,
  errorMessages,
  endpoint,
  required,
  emptyLabel = 'defaultSelectEmptyLabel',
  onChangeCallback,
  idsToHide,
  // if true the name (key/id) will be used to look up a client side translation instead of the description from the server.
  useClientTranslations = false,
  nameTranslationPrefix = '',
  errorTranslationPrefix = 'errorMessage.',
  ...props
}: LookupFieldProps) {
  const t = useLocalTranslation()
  const [lookupOptions, setLookupOptions] = useState<LookupFieldOptions | []>([])
  const { client } = useRequest()
  const lookupEndpoint = endpoint ? endpoint : `/api/v1/lookup?field=${lookupField}`
  const [selectedLookup, setSelectedLookup] = useState(defaultValue?.id ?? '')

  useQuery({
    queryKey: [lookupField],
    queryFn: async () => client.get<LookupFieldOptions>(lookupEndpoint),
    onSuccess: async ({ data }) => {
      if (idsToHide) {
        setLookupOptions(data.filter((option) => !idsToHide.includes(option.id.toString())))
      } else setLookupOptions(data)
    },
  })

  const onChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedLookup(event.target.value)
    onChangeCallback?.(event, lookupOptions)
  }

  return (
    <div className='sm:col-span-4'>
      <Title id={props.id} title={title} required={required} />
      <select
        name={props.id}
        value={selectedLookup}
        onChange={(event) => onChangeHandler(event)}
        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
        {...props}
      >
        <option value={''}>{t(emptyLabel)}</option>
        {lookupOptions.map(({ id, description, name }) => {
          let optionValue
          if (useClientTranslations) {
            optionValue = t([`${nameTranslationPrefix}${name}`, name])
          } else {
            optionValue = typeof description === 'string' ? t(description) : translateAndJoinList(t, description)
          }
          return (
            <option key={`${id}-${description}`} value={id}>
              {optionValue}
            </option>
          )
        })}
      </select>
      {helpText && <HelpText helpText={helpText} />}
      {hasError && errorMessages && (
        <InlineFieldError errorPrefix={errorTranslationPrefix} errorMessages={errorMessages} />
      )}
    </div>
  )
}
