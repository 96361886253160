import { useLocation, useSearchParams } from 'react-router-dom'
import CompanyLogo from '../../../../assets/logo.svg'
import { NotFound } from '../../../components/error-component/not-found'
import { useAuth } from '../../../auth'
import { LtiLaunchLoading } from './loading'
import { LtiLaunchContent } from './content'

export function LtiLaunch() {
  const [searchParams] = useSearchParams()
  const isLtiLaunch = !!searchParams.get('launchId')
  const { isLoading, isAuthenticated, login, error } = useAuth()
  const { pathname } = useLocation()

  if (!isLtiLaunch || (!isLoading && error)) {
    // This page should only be used to handle LTI launches initiated
    // after a LTI handshake.
    return (
      <NotFound
        primaryLink={{
          label: 'Go back',
          to: {
            pathname,
            search: searchParams.toString(),
          },
          replace: true,
        }}
      />
    )
  }

  return (
    <div className='grid place-items-center bg-gray-50 px-6 py-24 sm:py-32 lg:px-8'>
      <div className='text-center'>
        <div className='flex h-24 shrink-0 justify-center '>
          <img className='h-20 w-auto fill-current' src={CompanyLogo} alt='Virtual Medical Coaching logo' />
        </div>
      </div>
      {isLoading ? <LtiLaunchLoading /> : <LtiLaunchContent isAuthenticated={isAuthenticated} login={login} />}
    </div>
  )
}
