import { useTranslation as useTranslationI18Next } from 'react-i18next'
import { useEffect, useMemo, useRef, useState } from 'react'
import { DEFAULT_POLLING_INTERVAL_MS, DEFAULT_SIM_INSTALLATION_CHECK_TIMEOUT_MS, TITLE } from './constants'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useRequest } from './hooks/use-request'
import { fetchClient } from './api'
import { SimulationLaunchLocationState, SimulationSessionStatus } from './routes/simulation/launch'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { User, UserSummary } from './auth/types'
import { AxiosError, AxiosResponse } from 'axios'

interface SimulationStatusHookProps {
  productId?: string
  sessionUuid?: string
  onSimComplete: () => void
}

interface CloseSessionMutationHookProps {
  productId?: string
  sessionUuid?: string
  isLtiLaunch: boolean
}

interface CookieConsentData {
  id: number
  user: number
  allowEssentialCookies: boolean
}

export interface DeleteUserResponse {
  error: Array<string>
  success: Array<string>
}

export function useLocalTranslation() {
  return useTranslationI18Next('translation').t
}

export function useDocumentTitle(title: string | undefined | null) {
  const defaultTitle = useRef(document.title)

  useEffect(() => {
    document.title = title + ' - ' + TITLE ?? defaultTitle.current
  }, [title])

  useEffect(
    () => () => {
      document.title = defaultTitle.current
    },
    [],
  )
}

export function useSimulationStatus({ productId, sessionUuid, onSimComplete }: SimulationStatusHookProps) {
  const { client } = useRequest()

  const { data: simulationStatusData, isError: isStatusError } = useQuery({
    queryKey: ['status', client, sessionUuid, productId],
    queryFn: async () => {
      const res = await fetchClient<SimulationSessionStatus>(client).getItem(
        `/api/v1/product/${productId}/session/${sessionUuid}/status`,
      )
      return res?.data
    },
    enabled: !!sessionUuid,
    refetchInterval: (data) => (data?.status === 'complete' ? false : DEFAULT_POLLING_INTERVAL_MS),
  })

  useEffect(() => {
    if (simulationStatusData?.status === 'complete') {
      onSimComplete()
    }
  }, [simulationStatusData?.status])

  return {
    simulationStatusData,
    isStatusError,
  }
}

export function useSimLocationState() {
  const navigate = useNavigate()
  const { state: locationState } = useLocation() as unknown as Location & {
    state: SimulationLaunchLocationState
  }

  const { configuration, mode, protocol, productId } = useMemo(() => {
    return !locationState
      ? { configuration: undefined, mode: undefined, protocol: undefined, productId: undefined }
      : locationState
  }, [locationState])

  useEffect(() => {
    // If we don't have the launch state then go to the selection screen
    if (!configuration || !protocol || !mode || !productId) navigate('/simulation')
  }, [configuration, protocol, mode, productId])

  return {
    configuration,
    mode,
    protocol,
    productId,
  }
}

export function useCloseSessionMutation({ productId, sessionUuid, isLtiLaunch }: CloseSessionMutationHookProps) {
  const { client } = useRequest()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const handleNavigate = (data: SimulationSessionStatus) => {
    /*
      Handles user navigation after a simulation session is closed.

      There are 2 possible reporting use cases.
      1. Product requires evidence submission for records relating to the simulation to be visible/
      2. Product does not require evidence submission for records relating to the simulation to be visible.
    */

    if (data.hasReportDetails) {
      !data.hasSubmittedEvidence
        ? navigate(`${isLtiLaunch ? '/lti' : ''}/report/no-report`)
        : navigate(
            {
              pathname: `${isLtiLaunch ? '/lti' : ''}/report/${sessionUuid}`,
              ...(isLtiLaunch ? { search: searchParams.toString() } : undefined),
            },
            {
              replace: true,
            },
          )
    } else {
      ;['complete', 'closed'].includes(data.status)
        ? navigate('/report')
        : navigate(`${isLtiLaunch ? '/lti' : ''}/report/no-report`)
    }
  }

  return useMutation(
    () => client.post<SimulationSessionStatus>(`/api/v1/product/${productId}/session/${sessionUuid}/close`),
    {
      onSuccess: ({ data }) => handleNavigate(data),
    },
  )
}

export function useCheckSimInstalled() {
  const navigate = useNavigate()
  const timeOutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    // Ensure only one timer is active at a time
    if (!timeOutRef.current) {
      timeOutRef.current = setTimeout(() => {
        // Timer to redirect to the error page if the `blur`
        // handler is not triggered within `DEFAULT_SIM_INSTALLATION_CHECK_TIMEOUT_MS`
        navigate(`${location.pathname}/error`)
      }, DEFAULT_SIM_INSTALLATION_CHECK_TIMEOUT_MS)
    }

    const stopTimer = () => {
      // Implies that the sim is installed
      // So the timer is no longer needed.
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current)
        timeOutRef.current == null
      }
    }

    window.addEventListener('blur', stopTimer)
    return () => {
      window.removeEventListener('blur', stopTimer)
    }
  }, [])
}

export function useCookieConsentStatus({ user }: { user: User | undefined }) {
  const { client } = useRequest()
  const {
    data,
    isLoading: isLoadingCookieConsent,
    isFetching: isFetchingCookieConsent,
  } = useQuery(
    ['cookieConsent', user?.id],
    async () => {
      const res = await fetchClient<CookieConsentData>(client).getItem(`/api/v1/user/${user?.id}/cookie-preference`)
      return res?.data
    },
    {
      enabled: !!user?.id,
    },
  )

  return {
    isLoadingCookieConsent,
    isFetchingCookieConsent,
    data,
  }
}

export const useDeleteUser = () => {
  const [userForDeletion, setUserForDeletion] = useState<Pick<UserSummary, 'id' | 'fullName'> | null>(null)
  const { client } = useRequest()
  const { mutate, ...mutationProps } = useMutation<
    AxiosResponse<DeleteUserResponse>,
    AxiosError<DeleteUserResponse>,
    number
  >((userId: number) => client.delete(`/api/v1/user/${userId}`), { onSettled: () => setUserForDeletion(null) })
  const deleteUser = () => userForDeletion?.id && mutate(userForDeletion.id)

  return {
    userForDeletion,
    setUserForDeletion,
    deleteUser,
    ...mutationProps,
  }
}
