import classNames from 'classnames'
import { MouseEvent, useMemo } from 'react'
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid'
import { useLocalTranslation } from '../../../hooks'

export const NUM_VISIBLE_PAGINATION_LINKS = 6

export interface TableFooterProps {
  page: number
  setPage: (page: number) => void
  totalPages: number
  maxPageLinksVisible?: number
}

export const TableFooter = ({
  page,
  setPage,
  totalPages,
  maxPageLinksVisible = NUM_VISIBLE_PAGINATION_LINKS,
}: TableFooterProps) => {
  const t = useLocalTranslation()
  const numPageLinks = totalPages > maxPageLinksVisible ? maxPageLinksVisible : totalPages
  const pageLinksMidPoint = Math.ceil(maxPageLinksVisible / 2)
  const firstPage = 1
  const lastPage = totalPages
  // This flag determines whether to show the '...' and the first and the last page.
  const showPageRange = totalPages > maxPageLinksVisible
  // Dynamic pages are the page numbers we iterate over and show in the middle
  const numDynamicPages = showPageRange ? numPageLinks - 1 : numPageLinks

  // So if total pages is 10 and visible page links is 5, the delimiter in front of the last page should
  // be displayed when the active page is greater than 7 (10 - 3). Because when page > 7 is active, the last two pages
  // will go out of view and we must always display the last page if its not in view.
  const pageNumToShowLastPageDelimiter = totalPages - Math.ceil(numPageLinks / 2)

  // With numDynamicPages as 5 and first page set to 1, the first page delimiter is displayed
  // after page number 4 (3 + 1).
  const pageNumToShowFirstPageDelimiter = Math.ceil(numDynamicPages / 2) + firstPage

  // Base styles for page number buttons
  const basePageNumBtnStyles = 'inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium'
  // Base styles for 'Next' and 'Previous' buttons
  const basePaginationBtnStyles =
    'inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500'

  const firstVisiblePageLink: number = useMemo(() => {
    let firstVisiblePageLink = page - pageLinksMidPoint
    if (page <= pageLinksMidPoint) {
      firstVisiblePageLink = 0
    } else if (page > totalPages - pageLinksMidPoint) {
      firstVisiblePageLink = totalPages - maxPageLinksVisible
      if (firstVisiblePageLink < 0) firstVisiblePageLink = 0
    }
    return firstVisiblePageLink
  }, [totalPages, page, maxPageLinksVisible])

  return (
    <nav className='flex items-center justify-between border-t border-gray-200 px-4 sm:px-0'>
      <div className='-mt-px flex w-0 flex-1'>
        <button
          type='button'
          className={classNames(basePaginationBtnStyles, { 'hover:border-gray-300 hover:text-gray-700': page > 1 })}
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
        >
          <ArrowLongLeftIcon className='mr-3 h-5 w-5 text-gray-400' aria-hidden='true' />
          {t('previous')}
        </button>
      </div>
      <div className='hidden md:-mt-px md:flex'>
        {showPageRange && (
          <>
            {page >= pageNumToShowFirstPageDelimiter && (
              <button
                type='button'
                className={classNames(
                  basePageNumBtnStyles,
                  { 'border-blue-500 text-blue-600': firstPage === page },
                  { 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': 1 !== page },
                )}
                onClick={(e: MouseEvent<HTMLButtonElement>) => setPage(Number(e.currentTarget.value))}
                value={firstPage}
              >
                {firstPage}
              </button>
            )}
            {page > pageNumToShowFirstPageDelimiter ? <span className={basePageNumBtnStyles}>...</span> : null}
          </>
        )}
        {Array.from({ length: numDynamicPages }, (_, index) => {
          const pageNum = firstVisiblePageLink + index + 1
          {
            /* Current: 'border-blue-500 text-blue-600', Default: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300' */
          }
          return (
            <button
              type='button'
              className={classNames(
                basePageNumBtnStyles,
                { 'border-blue-500 text-blue-600': pageNum === page },
                { 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': pageNum !== page },
              )}
              onClick={(e: MouseEvent<HTMLButtonElement>) => setPage(Number(e.currentTarget.value))}
              value={pageNum}
              key={index}
            >
              {pageNum}
            </button>
          )
        })}
        {showPageRange && (
          <>
            {page >= pageNumToShowLastPageDelimiter ? null : <span className={basePageNumBtnStyles}>...</span>}
            <button
              type='button'
              className={classNames(
                basePageNumBtnStyles,
                { 'border-blue-500 text-blue-600': lastPage === page },
                { 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': lastPage !== page },
              )}
              onClick={(e: MouseEvent<HTMLButtonElement>) => setPage(Number(e.currentTarget.value))}
              value={lastPage}
            >
              {lastPage}
            </button>
          </>
        )}
      </div>
      <div className='-mt-px flex w-0 flex-1 justify-end'>
        <button
          type='button'
          className={classNames(basePaginationBtnStyles, {
            'hover:border-gray-300 hover:text-gray-700': page < totalPages,
          })}
          onClick={() => setPage(page + 1)}
          disabled={page === totalPages}
        >
          {t('next')}
          <ArrowLongRightIcon className='ml-3 h-5 w-5 text-gray-400' aria-hidden='true' />
        </button>
      </div>
    </nav>
  )
}
