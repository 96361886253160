import { UploadedFile } from '../../../../../components/file-upload/types'
import { useActionData, useLoaderData, useParams } from 'react-router-dom'
import { ApiResponse } from '../../../../../api'
import { FormNestedSection } from '../../../../../components/form-nested-group'
import { FileManager } from '../../../../../components/file-upload/manager'
import { downloadFile } from '../../../../../components/file-upload/download'
import { useRequest } from '../../../../../hooks/use-request'
import { ReadOnlyLookupField } from '../../../../../components/form-fields/read-only-lookup'
import { InputField } from '../../../../../components/form-fields/input'
import { LookupField, LookupFieldOption } from '../../../../../components/form-fields/lookup'
import { CheckboxField } from '../../../../../components/form-fields/checkbox'
import { Form } from '../../../../../components/form'
import { NavigateButton } from '../../../../../components/buttons/navigate-button'
import { SubmitButton } from '../../../../../components/buttons/submit-button'
import { FormWrapper } from '../../../../../components/form-wrapper'
import { useLocalTranslation } from '../../../../../hooks'

interface DownloadDetailsFormProps {
  action: 'create' | 'save'
}

export interface DownloadDetails {
  version: string
  operatingSystem: LookupFieldOption
  fileUrl: string
  isActive: boolean
  file?: UploadedFile
}

export const DownloadDetailsForm = (props: DownloadDetailsFormProps) => {
  const { client } = useRequest()
  const loaderData = useLoaderData() as ApiResponse<DownloadDetails>
  const actionData = useActionData() as ApiResponse<DownloadDetails>
  const { productId } = useParams()
  const t = useLocalTranslation()

  return (
    <>
      <FormWrapper
        isError={actionData?.isError}
        error={actionData?.error}
        isSuccess={actionData?.isSuccess}
        successRedirectTo={props.action === 'create' ? `..` : undefined}
        successMessage={props.action === 'save' ? t('simInstallationDownload.successMessage') ?? '' : undefined}
        locationState={{
          created: true,
          heading: t('simInstallationDownload.successMessage'),
        }}
      >
        <Form method={props.action === 'create' ? 'post' : 'put'}>
          <FormNestedSection title='Download details'>
            <ReadOnlyLookupField title='product' lookupField='product' lookupId={productId ?? ''} />
            <InputField
              id='version'
              title='version'
              required
              type='text'
              defaultValue={loaderData?.data?.version}
              hasError={!!actionData?.error}
              errorMessages={
                actionData?.error?.majorVersion || actionData?.error?.minorVersion || actionData?.error?.patchVersion
              }
            />
            <LookupField
              id='operatingSystem'
              lookupField='operatingSystem'
              title='operatingSystem'
              required
              defaultValue={loaderData?.data?.operatingSystem}
              hasError={!!actionData?.error}
              errorMessages={actionData?.error?.operatingSystem}
            />
            <InputField
              id='fileUrl'
              title='simInstallationFileUrl'
              required
              type='text'
              defaultValue={loaderData?.data?.fileUrl}
              hasError={!!actionData?.error}
              errorMessages={actionData?.error?.file}
            />
            {loaderData?.data?.file && (
              <FileManager
                file={loaderData?.data?.file}
                downloadAction={async (file: UploadedFile) => {
                  downloadFile({
                    client,
                    endpoint: `/api/v1/file/${file.id}/download/sim-installation-file`,
                  })
                }}
              />
            )}
            <CheckboxField
              id='isActive'
              type='checkbox'
              title='activateVersion'
              defaultChecked={loaderData?.data?.isActive ?? true}
              hasError={!!actionData?.error}
              errorMessages={actionData?.error?.isActive}
            />
          </FormNestedSection>
          <div className='flex items-center justify-end gap-x-6'>
            <NavigateButton translationText='cancel' navigateTo='..' color='white' />
            <SubmitButton translationText={props.action} />
          </div>
        </Form>
      </FormWrapper>
    </>
  )
}
