import { createColumnHelper } from '@tanstack/table-core'
import { Link, useParams, useMatches } from 'react-router-dom'
import { UserSummary } from '../../../../../../auth/types'
import { PageHeading } from '../../../../../../components/page-heading'
import { SummaryTable } from '../../../../../../components/table'
import { ColumnHeader } from '../../../../../../components/table/column-header'
import { useLocalTranslation } from '../../../../../../hooks'
import { LicenseBlockDetailsForm } from '../license-block-details-form'

export function UpdateLicenseBlock() {
  const t = useLocalTranslation()
  const { organisationId, licenseId } = useParams()

  const matches = useMatches()
  // The router parent for this page is licenses dashboard, and parent of that is the organisation wrapper,
  // Using pathname we can access the route node for the wrapper which is 2 parents higher.
  // -1 is the current node and so to navigate 2 nodes up the tree we need to use `-3` = -1 - 2
  const organisationWrapperPathname = matches.at(-3)?.pathname

  // Define the column configuration for the licensed users table
  const columnHelper = createColumnHelper<UserSummary>()
  const columns = [
    columnHelper.accessor('fullName', {
      header: () => <ColumnHeader header='name' />,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('email', {
      header: () => <ColumnHeader header='email' />,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('userRole', {
      header: () => <ColumnHeader header='role' />,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('id', {
      header: () => <span></span>,
      cell: (info) => (
        <Link
          // To redirect we need to access child nodes of the wrapper all the way down to user details page
          to={`${organisationWrapperPathname}/user/${info.cell.getValue()}`}
          // To be able to redirect back to this page on Cancel button of the user details page, store path in state JSX atribute
          state={{ cancelNavigateTo: matches.at(-1)?.pathname }}
          className='text-blue-500'
        >
          {t('edit')}
        </Link>
      ),
      enableSorting: false,
    }),
  ]

  return (
    <>
      <PageHeading translationText='editLicense' />
      <LicenseBlockDetailsForm action='save' />
      <h3 className='mt-12 font-semibold leading-8 text-gray-900 text-xl'>{t('licensedUsers')}</h3>
      <SummaryTable<UserSummary>
        columns={columns}
        defaultColumnSort={{
          id: 'id',
          desc: true,
        }}
        endpoint={`/api/v1/organisation/${organisationId}/license-block/${licenseId}/user`}
        showGlobalFilter
        filterPlaceholderText='searchNameOrEmail'
      />
    </>
  )
}
