import { useState } from 'react'
import { KEYCLOAK_AUTH } from '../../auth'
import { CookieConsentModal } from '../../components/cookie-consent-modal'
import { Divider } from '../../components/divider'
import { Form } from '../../components/form'
import { ReadOnlyField } from '../../components/form-fields/read-only'
import { FormNestedSection } from '../../components/form-nested-group'
import { FormWrapper } from '../../components/form-wrapper'
import { PageHeading } from '../../components/page-heading'
import { useLocalTranslation } from '../../hooks'
import { ColorPicker } from '../../components/color-picker'
import { useActionData, useLoaderData } from 'react-router-dom'
import { ApiResponse } from '../../api'
import { NavigateButton } from '../../components/buttons/navigate-button'
import { SubmitButton } from '../../components/buttons/submit-button'

export interface UserPreferencesData {
  id: number
  userId: number
  firstName: string
  lastName: string
  email: string
  allowEssentialCookies: boolean
  skinTone: number
}

export const UserPreferences = () => {
  const loaderData = useLoaderData() as ApiResponse<UserPreferencesData>
  const actionData = useActionData() as ApiResponse<UserPreferencesData>
  const [openCookieConsent, setOpenCookieConsent] = useState(false)
  const [selectedSkinTone, setSelectedSkinTone] = useState<number | undefined>(loaderData.data?.skinTone)
  const t = useLocalTranslation()

  return (
    <>
      <CookieConsentModal open={openCookieConsent} onClose={() => setOpenCookieConsent(false)} />
      <PageHeading translationText='preferences' />
      <FormWrapper
        isError={actionData?.isError}
        error={actionData?.error}
        isSuccess={actionData?.isSuccess}
        successMessage={t('userSkinTone.successMessage') ?? ''}
      >
        <Form method='put'>
          <FormNestedSection title='userDetails'>
            <ReadOnlyField title='name' defaultValue={`${loaderData.data?.firstName} ${loaderData.data?.lastName}`} />
            <ReadOnlyField title='emailAddress' defaultValue={loaderData.data?.email} />
            <input type='hidden' name='skinTone' value={selectedSkinTone} />
          </FormNestedSection>
          <div className='flex start'>
            <button
              type='button'
              className='text-blue-500 text-sm text-left'
              onClick={() => KEYCLOAK_AUTH.keycloak?.login({ action: 'UPDATE_PASSWORD' })}
            >
              {t('changePassword')}
            </button>
          </div>
          <div className='flex start'>
            <button
              type='button'
              className='text-blue-500 text-sm text-left'
              onClick={() => setOpenCookieConsent(true)}
            >
              {t('cookieConsent.popupLink')}
            </button>
          </div>
          <Divider />
          <FormNestedSection title='simulationPreferences'>
            <ColorPicker
              label='userSkinTone.fieldLabel'
              selectedColorId={selectedSkinTone ?? loaderData.data?.skinTone}
              setSelectedColorId={setSelectedSkinTone}
            />
          </FormNestedSection>
          <div className='flex items-center justify-end gap-x-6'>
            <NavigateButton translationText='cancel' navigateTo='..' color='white' />
            <SubmitButton translationText='save' />
          </div>
        </Form>
      </FormWrapper>
    </>
  )
}
