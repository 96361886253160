import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import CompanyLogo from '../../../assets/logo.svg'
import { SERVER_ADDRESS } from '../../constants'
import { useRequest } from '../../hooks/use-request'
import { SimInProgress } from './sim-in-progress'
import { SimNotStarted } from './sim-not-started'
import { ErrorBanner } from '../../components/error-banner'
import { NavigateButton } from '../../components/buttons/navigate-button'
import {
  useCloseSessionMutation,
  useDocumentTitle,
  useLocalTranslation,
  useSimLocationState,
  useSimulationStatus,
} from '../../hooks'
import { useMutation } from '@tanstack/react-query'

export const enum SimSessionStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  COMPLETE = 'complete',
}

export type SimulationLaunchLocationState = {
  configuration?: string
  mode?: 'VR' | '2D'
  protocol?: string
  productId: string
}

export type SimulationSessionStatus = {
  uuid: string
  ltiLaunchId: string
  status: string
  hasSubmittedEvidence: boolean
  hasReportDetails?: boolean
}

export function SimulationLaunch() {
  const { client } = useRequest()
  const t = useLocalTranslation()
  const [searchParams] = useSearchParams()
  const isLtiLaunch = !!searchParams.get('launchId')

  useDocumentTitle(t('launchSimulation'))

  const [state, setState] = useState<
    | {
        token: ''
        sessionUuid: ''
      }
    | undefined
  >(undefined)

  const { configuration, mode, protocol, productId } = useSimLocationState()

  const createSimulationSessionMutation = useMutation(
    () =>
      client.post(`/api/v1/product/${productId}/session`, {
        configuration,
        ltiLaunchId: searchParams.get('launchId'),
        mode,
      }),
    {
      onSuccess: (response) => setState({ token: response.data.token, sessionUuid: response.data.uuid }),
    },
  )

  useEffect(() => {
    if (configuration && productId && !state?.sessionUuid) {
      createSimulationSessionMutation.mutate()
    }
  }, [configuration, state?.sessionUuid, productId])

  const closeSessionMutation = useCloseSessionMutation({
    productId,
    sessionUuid: state?.sessionUuid,
    isLtiLaunch,
  })

  const { simulationStatusData, isStatusError } = useSimulationStatus({
    productId,
    sessionUuid: state?.sessionUuid,
    onSimComplete: () => {
      // Close the session once its been marked as 'complete'
      // A session can be marked as complete either by using `I've finished` action or exiting the simulation app.
      // A separate call to close the simulation is required to ensure the lti session data is sent in the request.
      // Before closing the session, the lti session data and the launch id are used to retrieve the launch instance
      // and communicate with the LTI Platform.
      closeSessionMutation.mutate()
    },
  })

  const url = useMemo(() => {
    if (!state || !productId) return undefined
    return `${protocol}://fetchconfig=${SERVER_ADDRESS}api/v1/product/${productId}/session/${state.sessionUuid}?token=${state.token}`
  }, [state?.sessionUuid, productId, SERVER_ADDRESS])

  if (state == undefined) {
    return null
  }

  return (
    <>
      <div className='grid place-items-center bg-gray-50 px-6 py-24 sm:py-32 lg:px-8'>
        {closeSessionMutation.isError && closeSessionMutation.error ? (
          <ErrorBanner errors={{ Session: ['Could not close the session. Please start over.'] }} />
        ) : null}
        {isStatusError ? (
          <ErrorBanner errors={{ Session: ['Could not fetch the updated session status. Please start over.'] }} />
        ) : null}
        <div className='text-center'>
          <div className='flex h-24 shrink-0 justify-center '>
            <img className='h-20 w-auto fill-current' src={CompanyLogo} alt='Virtual Medical Coaching logo' />
          </div>
        </div>
        {simulationStatusData?.status === SimSessionStatus.NOT_STARTED && (
          <SimNotStarted url={url} isLtiLaunch={isLtiLaunch} />
        )}
        {productId && simulationStatusData?.status === SimSessionStatus.IN_PROGRESS && (
          <SimInProgress productId={productId} sessionId={state.sessionUuid} />
        )}
        {closeSessionMutation.isError || isStatusError ? (
          <div className='flex flex-col gap-y-2 max-w-fit'>
            <NavigateButton
              translationText='report.startOver'
              navigateTo={{
                pathname: `${isLtiLaunch ? '/lti' : 'simulation'}/launch`,
                search: searchParams.toString(),
              }}
              color='blue'
            />
          </div>
        ) : null}
      </div>
    </>
  )
}
