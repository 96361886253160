import { Title } from './componentry/title'
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { InlineFieldError } from './componentry/error-message'
import { HelpText } from './componentry/helpText'

import { DATE_FORMAT } from '../../utils'
import { useLocalTranslation } from '../../hooks'

export interface DateFieldProps extends React.ComponentPropsWithRef<'input'> {
  title: string
  startDate: Date
  helpText?: string
  hasError?: boolean
  errorMessages?: Array<string>
}

export const DateField = ({ title, startDate, helpText, hasError, errorMessages, ...props }: DateFieldProps) => {
  const t = useLocalTranslation()
  const [date, setDate] = useState(startDate)

  return (
    <div className='sm:col-span-4'>
      <Title id={props.id} title={title} htmlFor={props.id} required={props.required} />
      <DatePicker
        dateFormat={DATE_FORMAT}
        name={title}
        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
        selected={date}
        onChange={(date: Date) => setDate(date)}
      />
      {helpText && <HelpText helpText={t(helpText)} />}
      {hasError && errorMessages && <InlineFieldError errorMessages={errorMessages} />}
    </div>
  )
}
