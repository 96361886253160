import { useDocumentTitle, useLocalTranslation } from '../../hooks'
import CompanyLogo from '../../../assets/logo.svg'

export function SessionNotSaved() {
  const t = useLocalTranslation()

  useDocumentTitle(t('report.sessionNotSavedTitle'))

  return (
    <div className=' grid min-h-full align-items-center bg-white px-6 py-24 sm:py-32 lg:px-8'>
      <div className='text-center'>
        <div className='flex h-24 shrink-0 justify-center '>
          <img className='h-20 w-auto fill-current' src={CompanyLogo} alt='Virtual Medical Coaching logo' />
        </div>

        <div className=' flex justify-center gap-4 rounded-md bg-red-50 width-full px-6 py-2 self-center'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#F87171' className='w-6 h-6'>
            <path
              fillRule='evenodd'
              d='M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z'
              clipRule='evenodd'
            />
          </svg>
          <p className=' text-red-900'>{t('report.sessionNotSaved')}</p>
        </div>
        <p className='mt-6 text-base leading-7 text-gray-600'>{t('report.noReport')}</p>
      </div>
    </div>
  )
}
