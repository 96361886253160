import { useLocalTranslation } from '../../../hooks'

interface TitleProps extends Partial<HTMLLabelElement> {
  title: string
  required?: boolean
}

export function Title({ title, ...props }: TitleProps) {
  const t = useLocalTranslation()
  return (
    <label htmlFor={props.id} className='block text-sm font-medium leading-6 text-gray-900'>
      {t(title)} {props.required && <span className='text-red-600'>*</span>}
    </label>
  )
}
