import debounce from 'lodash.debounce'
import { DEFAULT_DEBOUNCE_MS } from '../../constants'

interface FilterProps extends React.ComponentPropsWithRef<'input'> {
  filter: string
  setFilter: (value: string) => void
  filterPlaceholderText?: string
}

export function Filter({ filter, setFilter, filterPlaceholderText }: FilterProps) {
  return (
    <>
      <div className='max-w-lg flex-grow'>
        <input
          defaultValue={filter}
          className='block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
          placeholder={filterPlaceholderText}
          onChange={debounce(
            (e: React.ChangeEvent<HTMLInputElement>) => setFilter(e.target.value),
            DEFAULT_DEBOUNCE_MS,
          )}
        />
      </div>
    </>
  )
}
