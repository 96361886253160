import classNames from 'classnames'

const baseButtonStyle =
  'rounded-md px-3 py-2 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'

export type ButtonStyle = {
  enabledStyle: string
  disabledStyle: string
}

const baseBlueButtonStyle: string = classNames(baseButtonStyle, 'text-white')
export const blueButton: ButtonStyle = {
  enabledStyle: classNames(baseBlueButtonStyle, 'bg-blue-600 hover:bg-blue-500'),
  disabledStyle: classNames(baseBlueButtonStyle, 'bg-gray-600 hover:gray-500'),
}

const baseLightBlueButtonStyle: string = classNames(baseButtonStyle, 'text-blue-700')
export const lightBlueButton: ButtonStyle = {
  enabledStyle: classNames(baseLightBlueButtonStyle, 'bg-blue-100 hover:bg-blue-200'),
  disabledStyle: baseLightBlueButtonStyle, // No additional style for disabled light blue button yet
}

const baseWhiteButtonStyle: string = classNames(baseButtonStyle, 'text-black border-gray-300 border')
export const whiteButton: ButtonStyle = {
  // #fefefe is the lightest gray without going to pure white. There isn't much room between gray-50 and white
  enabledStyle: classNames(baseWhiteButtonStyle, 'bg-gray-50 hover:bg-[#fefefe]'),
  disabledStyle: baseWhiteButtonStyle, // No additional style for disabled white button yet
}

export const buttonColourSchemes: Record<string, ButtonStyle> = {
  blue: blueButton,
  'light-blue': lightBlueButton,
  white: whiteButton,
}
