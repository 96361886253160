import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import { Icon, IconTypes } from '../Icon'
import { useLocalTranslation } from '../../hooks'

export interface ListItemProps {
  displayNameTranslation: string
  href: string
  icon: IconTypes
  action?: () => void
  target?: string
}

export function NavItem({ displayNameTranslation, href, icon, action, target }: ListItemProps) {
  const t = useLocalTranslation()
  return (
    <>
      <NavLink
        to={href}
        onClick={() => action?.()}
        target={target}
        className={({ isActive }) =>
          classNames(
            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
            { 'bg-gray-100 text-gray-800 font-bold': isActive },
            { 'text-gray-700 hover:text-gray-600 hover:bg-gray-50': !isActive },
          )
        }
      >
        {({ isActive }) => (
          <>
            <Icon type={icon} color='gray' isBold={isActive} hasHoverState={true} />
            {t(displayNameTranslation)}
          </>
        )}
      </NavLink>
    </>
  )
}
