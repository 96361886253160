import { useCallback, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useAuth } from './index'
import { PageLoader } from '../components/loaders/page'

export const ProtectedRoute = () => {
  const { isAuthenticated, isLoading, error, login, logout } = useAuth()

  const { pathname } = useLocation()

  const loginWithRedirect = useCallback(() => login({ redirectPath: pathname }), [login, pathname])

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !error) loginWithRedirect()
  }, [isLoading, isAuthenticated, error])

  useEffect(() => {
    if (!isLoading && error) {
      // TODO: Report error
      console.error('A login error occurred')
      setTimeout(() => {
        logout()
      }, 10000)
    }
  }, [isLoading, error])

  if (isLoading || !isAuthenticated) {
    return <PageLoader />
  }

  return <Outlet />
}
