import classNames from 'classnames'

type UserNameAndEmailProps = {
  userName: string
  userEmail: string
  extraStyle?: string
}

export function UserNameAndEmail({ extraStyle, userEmail, userName }: UserNameAndEmailProps) {
  const baseStyle = 'xl:px-2'
  const isExtraStyleProvided = !!extraStyle
  const style = classNames(baseStyle, { [extraStyle as string]: isExtraStyleProvided })

  return (
    <div className={style}>
      <p className='font-bold text-gray-800'>{`${userName}`}</p>
      <p className='text-sm text-gray-800'>{`${userEmail}`}</p>
    </div>
  )
}
