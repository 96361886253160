import { useLocalTranslation } from '../../hooks'
import { NewOrUploadedFile, DownloadAction, UploadedFile, FileState } from './types'

import classNames from 'classnames'

export interface FileManagerProps {
  isDownloading?: boolean
  file: NewOrUploadedFile
  downloadAction?: DownloadAction | null
}

export function FileManager({ file, downloadAction = null, isDownloading }: FileManagerProps) {
  const t = useLocalTranslation()
  const baseStyles =
    'text-blue-700 py-2 px-4 rounded inline-flex items-center focus:z-10 focus:ring-4 focus:outline-none bg-blue-50'
  const disabled = file.state === FileState.UPLOADING
  const buttonLabel = file.state === FileState.UPLOADING ? t('uploading') : `${t('download')} ${file.originalName}`

  if (!file.originalName) {
    return null
  }

  return (
    <div className='sm:col-span-4'>
      <div className='flex items-center mb-2'>
        <button
          type='button'
          className={classNames(baseStyles, {
            'hover:bg-blue-100': !disabled && !isDownloading,
          })}
          onClick={(e) => {
            downloadAction?.(file as UploadedFile)
            e.preventDefault()
          }}
          disabled={disabled ?? isDownloading}
        >
          {file.state !== FileState.UPLOADING && !isDownloading ? (
            <svg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0 15C-2.41411e-08 14.4477 0.447715 14 1 14H13C13.5523 14 14 14.4477 14 15C14 15.5523 13.5523 16 13 16H1C0.447715 16 2.41411e-08 15.5523 0 15ZM3.29289 7.29289C3.68342 6.90237 4.31658 6.90237 4.70711 7.29289L6 8.58579L6 1C6 0.447716 6.44771 2.41411e-08 7 0C7.55228 -2.41411e-08 8 0.447715 8 1L8 8.58579L9.29289 7.29289C9.68342 6.90237 10.3166 6.90237 10.7071 7.29289C11.0976 7.68342 11.0976 8.31658 10.7071 8.70711L7.70711 11.7071C7.51957 11.8946 7.26522 12 7 12C6.73478 12 6.48043 11.8946 6.29289 11.7071L3.29289 8.70711C2.90237 8.31658 2.90237 7.68342 3.29289 7.29289Z'
                fill='#3B82F6'
              />
            </svg>
          ) : (
            <svg
              aria-hidden='true'
              className='w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600'
              viewBox='0 0 100 101'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                fill='currentColor'
              />
              <path
                d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                fill='currentFill'
              />
            </svg>
          )}
          <span className='pl-2'>{buttonLabel}</span>
        </button>
      </div>
    </div>
  )
}
