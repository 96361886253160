export const boolToFocalPoint = (bool: boolean) => (bool ? 'report.narrow' : 'report.broad')
export const boolToUsed = (bool: boolean) => (bool ? 'report.used' : 'report.notUsed')
export const boolToYesNo = (bool: boolean | undefined) => {
  if (bool === undefined) return 'report.N/A'
  return bool ? 'report.Yes' : 'report.No'
}
export const toDecimal1DP = (n: number): string => Number(n).toFixed(1)
export const toDecimal2DP = (n: number): string => Number(n).toFixed(2)
export const toSignificantFigure = (n: number): string => Number(n).toPrecision(4).toString()
export const getTimeConfig = (time: number): [string, { [key: string]: number }] => {
  if (typeof time === undefined) return ['-', { count: 0 }]
  if (time < 60) return ['report.timeToPerformSeconds', { seconds: time }]
  if (time % 60 == 0) return ['report.timeToPerformMinutes', { minutes: time / 60 }]

  const minutes = Math.floor(time / 60)
  const seconds = time - minutes * 60
  return ['report.timeToPerformMinutesSeconds', { minutes, seconds }]
}
export const toTranslatedString = (str: string) => `report.${str}`
