import { Link } from 'react-router-dom'
import { useLocalTranslation } from '../../hooks'

interface SimulationCategoryTileProps {
  url: string
  name: string
  displayName?: string
  imageDir: string
}

export const getImage = (filename: string | undefined) => {
  if (!filename) return require('../../../assets/images/placeholder.png')
  try {
    return require('../../../assets/images/' + filename)
  } catch (e) {
    return require('../../../assets/images/placeholder.png')
  }
}

export function SimulationCategoryTile({ url, name, displayName, imageDir }: SimulationCategoryTileProps) {
  const t = useLocalTranslation()
  return (
    <li>
      <Link className='py-5 hover:bg-gray-50 w-full h-full' to={url}>
        <div className='flex flex-no-wrap flex-col content-center gap-2 bg-gray-50 rounded-b-2xl shadow-xl pb-5 w-full h-full'>
          <img className='rounded-t-2xl cover object-center h-full' src={getImage(`${imageDir}/${name}.jpg`)} alt='' />
          <p className='pl-5 align-middle text-xl font-semibold leading-7 text-gray-900'>{displayName ?? t(name)}</p>
        </div>
      </Link>
    </li>
  )
}

export function SimulationCategoryTileWrapper({ children }: { children: React.ReactNode }) {
  return (
    <ul
      role='list'
      className='mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3'
    >
      {children}
    </ul>
  )
}
