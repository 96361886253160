import { useActionData, useLoaderData, useMatches, useParams } from 'react-router-dom'
import { LookupField } from '../../../components/form-fields/lookup'
import { useState } from 'react'
import { MimeTypes, NewFile, NewOrUploadedFile, UploadedFile } from '../../../components/file-upload/types'
import { FormWrapper } from '../../../components/form-wrapper'
import { FormNestedSection } from '../../../components/form-nested-group'
import { Form } from '../../../components/form'
import { InputField } from '../../../components/form-fields/input'
import { Divider } from '../../../components/divider'
import { FileUpload } from '../../../components/file-upload'
import { JSON_FILE_UPLOAD_VALIDATOR_PARAMS, buildDefaultValidators } from '../../../components/file-upload/validators'
import { NavigateButton } from '../../../components/buttons/navigate-button'
import { SubmitButton } from '../../../components/buttons/submit-button'
import { FileManager } from '../../../components/file-upload/manager'
import { useRequest } from '../../../hooks/use-request'
import { ApiResponse } from '../../../api'
import { FormElementProps } from '../../../components/form'
import { LocationStateProps } from '../../types'
import { downloadFile } from '../../../components/file-upload/download'
import { ReadOnlyField } from '../../../components/form-fields/read-only'
import { CheckboxField } from '../../../components/form-fields/checkbox'

export interface ProductConfigurationDetails {
  productId: string
  configurationName: string
  configFile: UploadedFile | null
  translationFile: UploadedFile | null
  isSelfAssessment: boolean
  practiceViewCategory: string
}

enum FILE_TYPE {
  CONFIG_FILE = 'CONFIG_FILE',
  TRANSLATION_FILE = 'TRANSLATION_FILE',
}

export function ProductConfigurationDetailsForm({ action }: FormElementProps) {
  const { productId, configurationId } = useParams()
  const loaderData = useLoaderData() as ApiResponse<ProductConfigurationDetails>
  const actionData = useActionData() as ApiResponse<LocationStateProps>
  const { client } = useRequest()
  const matches = useMatches()
  // The router parent for this page is an outlet and the redirect needs to navigate to the product.
  // Using pathname we can access the correct route node which is 2 parents higher. -1 is the current node
  // and so to navigate 2 nodes up the tree we need to use `-3` = -1 - 2
  const parentPathname = matches.at(-3)?.pathname

  const [isLoadingConfigFile, setIsLoadingConfigFile] = useState(false)
  const [isLoadingConfigTranslationFile, setIsLoadingConfigTranslationFile] = useState(false)
  const [uploadedConfigFile, setUploadedConfigFile] = useState<NewOrUploadedFile | null>(loaderData?.data?.configFile)
  const [uploadedTranslationFile, setUploadedTranslationFile] = useState<NewOrUploadedFile | null>(
    loaderData?.data?.translationFile,
  )
  const FILE_TYPE_SETTER_MAP = {
    [FILE_TYPE.CONFIG_FILE]: {
      setState: setUploadedConfigFile,
      setLoadingState: setIsLoadingConfigFile,
    },
    [FILE_TYPE.TRANSLATION_FILE]: {
      setState: setUploadedTranslationFile,
      setLoadingState: setIsLoadingConfigTranslationFile,
    },
  }

  const onFileUploaded = (file: UploadedFile, fileType: FILE_TYPE) => {
    FILE_TYPE_SETTER_MAP[fileType].setState(file)
    FILE_TYPE_SETTER_MAP[fileType].setLoadingState(false)
  }

  const onFileUploading = (file: NewFile, fileType: FILE_TYPE) => {
    FILE_TYPE_SETTER_MAP[fileType].setState(file)
    FILE_TYPE_SETTER_MAP[fileType].setLoadingState(true)
  }

  const onFileUploadFailed = (fileType: FILE_TYPE) => {
    FILE_TYPE_SETTER_MAP[fileType].setState(null)
    FILE_TYPE_SETTER_MAP[fileType].setLoadingState(false)
  }

  return (
    <FormWrapper
      isError={actionData?.isError}
      error={actionData?.error}
      isSuccess={actionData?.isSuccess}
      successRedirectTo={action === 'create' ? parentPathname : undefined}
      successMessage={action === 'save' ? 'Product configuration saved' : undefined}
      locationState={actionData?.data}
    >
      <Form method='post'>
        <FormNestedSection title='configurationDetails'>
          {configurationId && <ReadOnlyField title='id' value={configurationId} />}

          <InputField
            id='configurationName'
            title='configurationName'
            required
            type='text'
            defaultValue={loaderData?.data?.configurationName}
            hasError={!!actionData?.error}
            errorMessages={actionData?.error?.configurationName}
          />

          <input type='hidden' name='configFile' value={uploadedConfigFile?.id} defaultValue={uploadedConfigFile?.id} />

          <FileUpload
            allowedFileTypes={[MimeTypes.JSON]}
            onFileUploading={(file) => onFileUploading(file, FILE_TYPE.CONFIG_FILE)}
            onFileUploaded={(file) => onFileUploaded(file, FILE_TYPE.CONFIG_FILE)}
            onFileUploadFailed={() => onFileUploadFailed(FILE_TYPE.CONFIG_FILE)}
            fileValidators={buildDefaultValidators(JSON_FILE_UPLOAD_VALIDATOR_PARAMS)}
            endpoint={`/api/v1/file/upload/sim-config`}
            disabled={isLoadingConfigFile}
            title='JSON configuration file'
            required
            hasError={!!actionData?.error}
            errorMessages={actionData?.error?.configFile}
          />

          {uploadedConfigFile && (
            <FileManager
              file={uploadedConfigFile}
              isDownloading={isLoadingConfigFile}
              downloadAction={async (file: UploadedFile) => {
                downloadFile({
                  client,
                  endpoint: `/api/v1/file/${file.id}/download/sim-config`,
                })
              }}
            />
          )}

          <input
            type='hidden'
            name='translationFile'
            value={uploadedTranslationFile?.id}
            defaultValue={uploadedTranslationFile?.id}
          />

          <FileUpload
            allowedFileTypes={[MimeTypes.JSON]}
            onFileUploading={(file) => onFileUploading(file, FILE_TYPE.TRANSLATION_FILE)}
            onFileUploaded={(file) => onFileUploaded(file, FILE_TYPE.TRANSLATION_FILE)}
            onFileUploadFailed={() => onFileUploadFailed(FILE_TYPE.TRANSLATION_FILE)}
            fileValidators={buildDefaultValidators(JSON_FILE_UPLOAD_VALIDATOR_PARAMS)}
            endpoint={`/api/v1/file/upload/sim-config-translation`}
            disabled={isLoadingConfigTranslationFile}
            title='Translation file'
            hasError={!!actionData?.error}
            errorMessages={actionData?.error?.translationFileId}
          />

          {uploadedTranslationFile && (
            <FileManager
              file={uploadedTranslationFile}
              isDownloading={isLoadingConfigTranslationFile}
              downloadAction={async (file: UploadedFile) => {
                downloadFile({
                  client,
                  endpoint: `/api/v1/file/${file.id}/download/sim-config-translation`,
                })
              }}
            />
          )}
        </FormNestedSection>

        <Divider />
        <FormNestedSection>
          <CheckboxField
            id='isSelfAssessment'
            type='checkbox'
            title='isSelfAssessment'
            defaultChecked={loaderData?.data?.isSelfAssessment ?? true}
            hasError={!!actionData?.error}
            errorMessages={actionData?.error?.isSelfAssessment}
          />
          <LookupField
            id='practiceViewCategory'
            lookupField='practiceViewCategory'
            title='practiceActivity'
            endpoint={`/api/v1/lookup?product_id=${productId}&field=practice_view_category`}
            required
            defaultValue={{ id: loaderData?.data?.practiceViewCategory, description: [''], name: '' }}
            hasError={!!actionData?.error}
            errorMessages={actionData?.error?.practiceViewCategory}
          />
        </FormNestedSection>

        <div className='flex items-center justify-end gap-x-6'>
          <NavigateButton translationText='cancel' navigateTo={parentPathname ?? '..'} color='white' />
          <SubmitButton translationText={action} />
        </div>
      </Form>
    </FormWrapper>
  )
}
