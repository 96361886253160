import { Outlet, matchPath, useLocation, useParams } from 'react-router-dom'
import { SectionHeadingWithNav } from '../../../components/section-heading-with-nav'
import { PageHeadingLookup } from '../../../components/page-heading-lookup'

export interface CanDisplayRoutes {
  route: string
}

export interface Headings {
  route: string
  title: string
}

export const OrganisationWrapper = () => {
  const { pathname } = useLocation()
  const { organisationId } = useParams()

  const displaySectionHeadingWithNavRoutes: CanDisplayRoutes[] = [
    { route: `/admin/organisation/${organisationId}` },
    { route: `/admin/organisation/${organisationId}/license` },
    { route: `/admin/organisation/${organisationId}/user` },
  ]

  const isActiveSectionHeadingNav = displaySectionHeadingWithNavRoutes.some(({ route }) =>
    Boolean(matchPath(route, pathname)),
  )

  return (
    <>
      {isActiveSectionHeadingNav && (
        <PageHeadingLookup lookupField='organisation' lookupId={organisationId ? organisationId : ''} />
      )}
      {isActiveSectionHeadingNav && <SectionHeadingWithNav route='organisation' />}
      <Outlet />
    </>
  )
}
