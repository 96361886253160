export const enum UserPermissions {
  CLIENT_ACCESS_ADMIN_SECTION = 'global.client_access_admin_section',
  CREATE_ORG = 'global.create_org',
  UPDATE_ORG = 'global.update_org',
  VIEW_ORG = 'global.view_org',
  VIEW_USER = 'global.view_user',
  CREATE_USER = 'global.create_user',
  UPDATE_USER = 'global.update_user',
  REMOVE_USER = 'global.remove_user',
  MANAGE_SIM_CONFIG = 'global.manage_sim_config',
  ACCESS_SIMS = 'global.access_sims',
  EXPORT_USER_DATA = 'global.export_user_data',
  CONTACT_VMC_SUPPORT = 'global.contact_vmc_support',
  MANAGE_VALIDATION_SCHEMA = 'global.manage_validation_schema',
  MANAGE_REPORT_DATA = 'global.manage_report_data',
  VIEW_ALL_REPORTS = 'global.view_all_reports',
  VIEW_ORG_REPORTS = 'global.view_org_reports',
  VIEW_OWN_REPORTS = 'global.view_own_reports',
  VIEW_LICENSE_BLOCK = 'global.view_license_block',
  CREATE_LICENSE_BLOCK = 'global.create_license_block',
  UPDATE_LICENSE_BLOCK = 'global.update_license_block',
  UNASSIGN_LICENSE = 'global.unassign_license',
}

export interface User {
  id: number
  firstName: string
  lastName: string
  email: string
  roles: string[]
  permissions: UserPermissions[]
  language: string
  isBrightspaceAuthRequired?: boolean
  brightspaceUserMismatch?: boolean
}

export interface UserSummary {
  id: number
  userId: number
  fullName: string
  email: string
  licenseNames: string
  userRole: string
}
