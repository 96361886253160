import { useMutation } from '@tanstack/react-query'
import { useRequest } from '../../hooks/use-request'
import { useDocumentTitle, useLocalTranslation } from '../../hooks'
import { Trans } from 'react-i18next'
import { ErrorBanner } from '../../components/error-banner'

interface SimInProgressProps {
  productId: string
  sessionId: string
}

export function SimInProgress(props: SimInProgressProps) {
  const t = useLocalTranslation()
  const { client } = useRequest()
  const mutation = useMutation(() =>
    client.post(`/api/v1/product/${props.productId}/session/${props?.sessionId}/complete`),
  )
  const messageStyles = 'my-6 text-base leading-7 text-gray-600 text-center'

  useDocumentTitle(t('launch.inProgressTitle'))

  return (
    <>
      <p className={messageStyles}>{t('launch.inProgressLabel')}</p>
      {mutation.isError && <ErrorBanner errors={{ 'launch.status': ['launch.statusCompleteError'] }}></ErrorBanner>}
      <p className={messageStyles}>
        <Trans i18nKey='launch.inProgressMessage'>
          Once the simulation has finished you will be redirected to your report. <br /> If you have exited the
          simulation and have not been redirected please
          <button
            className='text-blue-500'
            type='button'
            onClick={() => mutation.mutate()}
            disabled={mutation.isLoading}
          >
            click here.
          </button>
        </Trans>
      </p>
    </>
  )
}
