import { isRouteErrorResponse, useNavigate, useRouteError } from 'react-router-dom'
import { ErrorComponenProps, ErrorComponent } from '../components/error-component'
import { AxiosError, HttpStatusCode } from 'axios'
import { useLocalTranslation } from '../hooks'

export const ErrorElement = (links?: Pick<ErrorComponenProps, 'primaryLink' | 'secondaryLink'>) => {
  const error = useRouteError()
  const navigate = useNavigate()
  const t = useLocalTranslation()
  if (isRouteErrorResponse(error)) {
    return (
      <ErrorComponent
        statusCode={HttpStatusCode.NotFound}
        heading={t('pageNotFound')}
        message={t('sorryWeCouldntFindThePageYoureLookingFor')}
        primaryLink={links?.primaryLink}
        secondaryLink={links?.secondaryLink}
      />
    )
  } else if (
    error instanceof AxiosError &&
    error.response?.status &&
    [HttpStatusCode.Forbidden, HttpStatusCode.Unauthorized, HttpStatusCode.NotFound].includes(error.response?.status)
  ) {
    return (
      <ErrorComponent
        statusCode={HttpStatusCode.NotFound}
        heading={t('pageNotFound')}
        message={t('sorryWeCouldntFindThePageYoureLookingFor')}
        primaryLink={links?.primaryLink}
        secondaryLink={links?.secondaryLink ?? { to: '#', onClick: () => navigate(0), label: t('refreshPage') }}
      />
    )
  } else {
    return (
      <ErrorComponent
        heading={t('somethingWentWrong')}
        message={t('contactYourAdministrator')}
        primaryLink={links?.primaryLink}
        secondaryLink={links?.secondaryLink}
      />
    )
  }
}
