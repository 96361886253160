import { createColumnHelper } from '@tanstack/react-table'
import { Link, useLocation, useParams } from 'react-router-dom'
import { ColumnHeader } from '../../../../components/table/column-header'
import { SummaryTable } from '../../../../components/table'
import { useLocalTranslation } from '../../../../hooks'

interface Configuration {
  id: number
  configurationName: string
  practiceViewCategory: [string]
}

export function ProductConfiguration() {
  const t = useLocalTranslation()
  const { productId } = useParams()
  const { pathname } = useLocation()

  // Define the column configuration for the table
  const columnHelper = createColumnHelper<Configuration>()
  const columns = [
    columnHelper.accessor('id', {
      header: () => <ColumnHeader header='id' />,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('configurationName', {
      header: () => <ColumnHeader header='configurationName' />,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('practiceViewCategory', {
      header: () => <ColumnHeader header='practiceActivity' />,
      // No need to call useLocalTranslation since this value comes already translated from the API
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('id', {
      header: () => <span></span>,
      cell: (info) => (
        <Link to={`${pathname}/configuration/${info.getValue()}`} className='text-blue-500'>
          {t('edit')}
        </Link>
      ),
      enableSorting: false,
    }),
  ]

  return (
    <>
      <SummaryTable<Configuration>
        columns={columns}
        defaultColumnSort={{
          id: 'id',
          desc: true,
        }}
        endpoint={`/api/v1/product/${productId}/configuration`}
        navigationButtonProps={{
          iconType: 'PlusIcon',
          translationText: 'newConfiguration',
          navigateTo: `${pathname}/configuration/new`,
          color: 'blue',
        }}
        showGlobalFilter
        filterPlaceholderText='searchIDConfigurationOrPracticeActivity'
      />
    </>
  )
}
