import classNames from 'classnames'
import { useLocalTranslation } from '../hooks'

interface PillProps {
  isActive: boolean
}

export function Pill({ isActive }: PillProps) {
  const text = isActive ? 'active' : 'inactive'
  const t = useLocalTranslation()

  return (
    <span className={classNames('py-1.5 px-4 rounded-2xl', { 'bg-green-100': isActive }, { 'bg-gray-100': !isActive })}>
      {t(text)}
    </span>
  )
}
