import { Config, RadiographyEvidenceData } from './report-types'
import {
  boolToFocalPoint,
  boolToUsed,
  boolToYesNo,
  getTimeConfig,
  toDecimal1DP,
  toDecimal2DP,
  toSignificantFigure,
  toTranslatedString,
} from './utils'

export const getSettingsConfig = (data: RadiographyEvidenceData): Config => {
  return {
    patientPosition: {
      value: data.patientPosition,
      valueType: 'rawString',
    },
    kVp: {
      value: toDecimal2DP(data.kVp),
      valueType: 'number',
    },
    mAs: {
      value: toDecimal2DP(data.mAs),
      valueType: 'decimal',
    },
    mA: {
      value: toDecimal2DP(data.mA),
      valueType: 'number',
    },
    ms: {
      value: toDecimal2DP(data.ms),
      valueType: 'number',
    },
    gridUsed: {
      value: boolToYesNo(data.gridUsed),
      valueType: 'simpleIntl',
    },
    suspendedRespiration: {
      value: boolToYesNo(data.suspendedRespiration),
      valueType: 'simpleIntl',
    },
    timeToPerform: {
      value: getTimeConfig(data.timeToPerform),
      valueType: 'complexIntl',
    },
    doorClosed: {
      value: boolToYesNo(data.doorClosed),
      valueType: 'simpleIntl',
    },
    cassetteInBucky: {
      value: toTranslatedString(data.cassetteInBucky),
      valueType: 'simpleIntl',
    },
    weightUsed: {
      value: toTranslatedString(data.weightUsed),
      valueType: 'simpleIntl',
    },
  }
}

export const getConsoleConfig = (data: RadiographyEvidenceData): Config => {
  return {
    patientSize: {
      value: toTranslatedString(data.console.patientSize),
      valueType: 'simpleIntl',
    },
    detectorMode: {
      value: toTranslatedString(data.console.detectorMode),
      valueType: 'simpleIntl',
    },
    AEC: {
      value: boolToYesNo(data.console.AEC),
      valueType: 'simpleIntl',
    },
    focalPoint: {
      value: boolToFocalPoint(data.console.focalPoint),
      valueType: 'simpleIntl',
    },
    leftAECChamber: {
      value: boolToUsed(data.console.leftAECChamber),
      valueType: 'simpleIntl',
    },
    middleAECChamber: {
      value: boolToUsed(data.console.middleAECChamber),
      valueType: 'simpleIntl',
    },
    rightAECChamber: {
      value: boolToUsed(data.console.rightAECChamber),
      valueType: 'simpleIntl',
    },
    consolePreset: {
      value: data.console.consolePreset,
      valueType: 'rawString',
    },
    mAsMode: {
      value: boolToYesNo(data.console.mAsMode),
      valueType: 'simpleIntl',
    },
  }
}

export const getTubeConfig = (data: RadiographyEvidenceData): Config => {
  return {
    collimatorTransverse: {
      value: `${data.tube.collimatorTransverse}${data.tube.distanceUnits}`,
      valueType: 'rawString',
    },
    collimatorLong: {
      value: `${data.tube.collimatorLong}${data.tube.distanceUnits}`,
      valueType: 'rawString',
    },
    tubeAngulation: {
      value: ['report.valueWithDegrees', { count: Number(data.tube.tubeAngulation) }],
      valueType: 'complexIntl',
    },
    tubeRotation: {
      value: ['report.valueWithDegrees', { count: Number(data.tube.tubeRotation) }],
      valueType: 'complexIntl',
    },
    tubeDistance: {
      value: `${toDecimal1DP(data.tube.tubeDistance)}${data.tube.distanceUnits}`,
      valueType: 'rawString',
    },
  }
}

export const getRadiationExposureConfig = (data: RadiographyEvidenceData): Config => {
  return {
    DAP: {
      // The units for this value will be dynamically generated from a value passed in the evidence object
      // in future versions of the simulation application
      value: `${toDecimal2DP(data.DAP)} mGy*cm²`,
      valueType: 'rawString',
    },
    effectiveDose: {
      value: `${toSignificantFigure(data.effectiveDose)} µSv`,
      valueType: 'rawString',
    },
    totalDAP: {
      // The units for this value will be dynamically generated from a value passed in the evidence object
      // in future versions of the simulation application
      value: `${toDecimal2DP(data.totalDAP)} mGy*cm²`,
      valueType: 'rawString',
    },
    totalEffectiveDose: {
      value: `${toSignificantFigure(data.totalEffectiveDose)} µSv`,
      valueType: 'rawString',
    },
  }
}
