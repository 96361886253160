import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  .init({
    fallbackLng: 'en',
    ns: ['translation'],
    fallbackNS: 'translation',
    debug: process.env.NODE_ENV === 'development', // Debug mode turned on in the development environment only
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

export default i18n

export const handleLanguage = (language: string | undefined) => {
  if (i18n.language != language) {
    i18n.changeLanguage(language)
  }
}
