import { Outlet, ScrollRestoration, useNavigate } from 'react-router-dom'
import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { SidebarMenu } from './sidebar-menu'
import MainPageWrapper from './main-page-wrapper'
import { useUser } from '../auth'
import { handleLanguage } from '../../i18nconfig'
import { Breadcrumbs } from './breadcrumbs'
import { NotFound } from './error-component/not-found'
import { CookieConsentModal } from './cookie-consent-modal'
import { PageLoader } from './loaders/page'
import { useCookieConsentStatus } from '../hooks'

export function PageLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [user, isLoading] = useUser()
  const navigate = useNavigate()

  const { data, isLoadingCookieConsent, isFetchingCookieConsent } = useCookieConsentStatus({ user })

  // The language is set in the page layout because the useUser hook cannot be accessed outside
  // of a protected route. PageLayout is the first child and allows the I18nextProvider language
  // provider to be updated
  useEffect(() => {
    if (!isLoading) handleLanguage(user?.language)
  }, [user?.language, isLoading])

  useEffect(() => {
    if (!isLoading && user?.isBrightspaceAuthRequired) {
      // Redirect to Brightspace auth if the user is not authenticated with it
      window.location.href = '/api/brightspace-auth?redirect_path=' + window.location.pathname
    } else if (!isLoading && user?.brightspaceUserMismatch) {
      navigate('/brightspace-user-mismatch', { state: { from: window.location.pathname } })
    }
  }, [user?.isBrightspaceAuthRequired, user?.brightspaceUserMismatch, isLoading])

  // The page layout and its children must not be displayed in an iframe.
  if (window.self !== window.top) {
    return <NotFound />
  }

  if (isLoading || isLoadingCookieConsent || isFetchingCookieConsent) {
    return <PageLoader />
  }

  return (
    <>
      <div>
        <CookieConsentModal open={!data?.allowEssentialCookies} />
        <ScrollRestoration />
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as='div' className='relative z-10 lg:hidden' onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed top-0 left-0 bottom-0 right-0 bg-gray-900/80' />
            </Transition.Child>

            <div className='fixed top-0 left-0 flex'>
              <Transition.Child
                as={Fragment}
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'
              >
                <Dialog.Panel className='relative mr-16 flex w-full max-w-xs flex-1'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
                      <button type='button' className='-m-2.5 p-2.5' onClick={() => setSidebarOpen(false)}>
                        <span className='sr-only'>Close sidebar</span>
                        <XMarkIcon className='h-6 w-6 text-white' aria-hidden='true' />
                      </button>
                    </div>
                  </Transition.Child>

                  {/* SidebarMenu for mobile/tablet viewports */}
                  <SidebarMenu />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className='hidden lg:fixed lg:top-0 lg:bottom-0 lg:flex lg:w-72 lg:flex-col'>
          <SidebarMenu />
        </div>

        <div className='sticky top-0 z-40 flex items-center gap-x-6 bg-gray-50 px-4 py-4 shadow-sm sm:px-6 lg:hidden'>
          <button type='button' className='-m-2.5 p-2.5 text-gray-700 lg:hidden' onClick={() => setSidebarOpen(true)}>
            <span className='sr-only'>Open sidebar</span>
            <Bars3Icon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <main className='py-10 lg:pl-72'>
          <MainPageWrapper>
            <Breadcrumbs />
            <Outlet />
          </MainPageWrapper>
        </main>
      </div>
    </>
  )
}
