import { PageHeading } from '../../../../../../components/page-heading'
import { DownloadDetailsForm } from '../download-details-form'

export function NewDownload() {
  return (
    <>
      <PageHeading translationText='addDownload' />
      <DownloadDetailsForm action='create' />
    </>
  )
}
