import { Link } from 'react-router-dom'

export interface MailtoButtonProps {
  emailAddress: string
  label: string
}

export function MailtoButton({ emailAddress, label }: MailtoButtonProps) {
  return (
    <Link
      to='#'
      onClick={(e) => {
        window.location.href = 'mailto:' + emailAddress
        e.preventDefault()
      }}
    >
      {label}
    </Link>
  )
}
