import { Button } from '../../../components/buttons/generic-button'

type PreviousNextNavigationProps = {
  className: string
  isFirstPage: boolean
  isLastPage: boolean
  onClickPrevious: () => void
  onClickNext: () => void
}

export function PreviousNextNavigation(props: PreviousNextNavigationProps) {
  return (
    <div className={props.className}>
      <Button
        translationText='previous'
        iconType='ArrowLeftIcon'
        iconPlacement='left'
        disabled={props.isFirstPage}
        onClick={props.onClickPrevious}
      />
      <Button
        translationText='next'
        iconType='ArrowRightIcon'
        iconPlacement='right'
        disabled={props.isLastPage}
        onClick={props.onClickNext}
      />
    </div>
  )
}
