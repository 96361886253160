import { useLocalTranslation } from '../../hooks'
import { InlineFieldError } from './componentry/error-message'
import { HelpText } from './componentry/helpText'
import { Title } from './componentry/title'
import { InputFieldProps } from './input'

export function CheckboxField({ title, helpText, errorMessages, hasError, required, ...props }: InputFieldProps) {
  const t = useLocalTranslation()
  return (
    <div className='flex items-center'>
      <input
        type={props.type}
        name={props.id}
        className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 mr-2'
        {...props}
      />
      <Title id={props.id} title={title} required={required} />
      {helpText && <HelpText helpText={t(helpText)} />}
      {hasError && errorMessages && <InlineFieldError errorMessages={errorMessages} />}
    </div>
  )
}
