import { useMemo } from 'react'
import { useUser } from '.'
import { User, UserPermissions } from './types'

/*
Checks a given user have any of the specified permissions.
*/
export function canUser(user: User, permissions: UserPermissions | UserPermissions[]) {
  const permissionsList = Array.isArray(permissions) ? permissions : [permissions]
  return user.permissions.some((perm) => permissionsList.includes(perm))
}

/*
  Hook to check if the current user has any of the specified permissions.
  Depends on useUsers so should only be used within ProtectedRoutes.
  */
export function usePermission(permissions: UserPermissions | UserPermissions[]): boolean {
  const [user, isLoading] = useUser()
  const hasPermission = useMemo(() => !isLoading && !!user && canUser(user, permissions), [user, permissions])

  return hasPermission
}
