import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { ChevronUpDownIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import { sortByIconStyle } from './styles'

export interface TableProps<T, V> extends Partial<PaginationState> {
  data: Array<T>
  columns: ColumnDef<T, V>[]
  sorting: SortingState
  onSortingChange?: OnChangeFn<SortingState>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Table = <T, V = any>({
  data,
  columns,
  sorting,
  onSortingChange,
  pageIndex = 1,
  pageSize = 20,
}: TableProps<T, V>) => {
  const table = useReactTable<T>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      pagination: {
        pageIndex,
        pageSize,
      },
    },
    initialState: {
      pagination: {
        pageIndex,
        pageSize,
      },
      sorting,
    },
    onSortingChange,
    enableSorting: true,
    manualPagination: true,
    enableSortingRemoval: false,
    manualFiltering: true,
  })

  return (
    <table className='min-w-full divide-y divide-gray-300'>
      <thead className='bg-gray-50'>
        {table.getHeaderGroups().map((headerGroup, index) => (
          <tr key={`${headerGroup.id}-${index}`}>
            {headerGroup.headers.map((header, index) => (
              <th
                key={`${header.id}-${index}`}
                className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                onClick={header.column.getToggleSortingHandler()}
              >
                <span className='flex'>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  {{
                    desc: <ChevronDownIcon className={sortByIconStyle} />,
                    asc: <ChevronUpIcon className={sortByIconStyle} />,
                  }[header.column.getIsSorted() as string] ??
                    (header.column.getCanSort() && <ChevronUpDownIcon className={sortByIconStyle} />)}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className='divide-y divide-gray-200 bg-gray-50'>
        {table.getRowModel().rows.map((row, index) => (
          <tr key={`${row.id}-${index}`}>
            {row.getVisibleCells().map((cell, index) => (
              <td
                key={`${row.id}-${cell.id}-${index}`}
                className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
