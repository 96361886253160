import { ProductCategories, PracticeViewCategory } from '../../routes/simulation/[productId]/category'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Crumb } from './crumb'
import { Match } from './index'

interface DynamicBreadcrumbsProps {
  dynamicRouteRoot: string
  matches: Match[]
}

export function getCategoryChain(categories: PracticeViewCategory[], ids: number[]): PracticeViewCategory[] {
  const [currentLevel, ...remainingIds] = ids
  const selectedCategory = categories.filter(({ id }) => id === currentLevel)[0]

  return selectedCategory === undefined || remainingIds.length === 0
    ? [selectedCategory]
    : [selectedCategory, ...getCategoryChain(selectedCategory.children, remainingIds)]
}

// TODO Rename to simulation breadcrumb.
export function DynamicBreadcrumbs({ dynamicRouteRoot, matches }: DynamicBreadcrumbsProps) {
  const parameters = useParams()
  const match = matches.filter((m) => m.pathname.includes(dynamicRouteRoot)).find((m) => !!m.data)
  const product = match?.data?.data as unknown as ProductCategories | undefined

  const ids = useMemo(
    () =>
      (parameters['*'] ?? '')
        ?.split('/')
        .filter((v) => !!v)
        .map((id) => parseInt(id)),
    [parameters],
  )

  const categories = useMemo(
    () => (ids.length === 0 ? [] : getCategoryChain(product?.categories ?? [], ids)),
    [product?.categories, ids],
  )

  const pathForLevel = useCallback(
    (level: number) => {
      const path = ids.slice(0, level).join('/')
      return `/simulation/${product?.id}/${path}`
    },
    [product, ids],
  )

  if (!product) return null

  return (
    <nav className='flex' aria-label='Breadcrumb'>
      <ol role='list' className='flex items-center space-x-4'>
        {['/simulation/'].includes(match?.pathname ?? '') ? null : (
          <>
            <Crumb index={0} name={'simulations'} pathname={'/simulation'} />
            {categories.length > 0 && <Crumb index={1} name={product.name} pathname={`/simulation/${product.id}`} />}
            {categories.slice(0, -1).map((category, index) => {
              return (
                <Crumb key={index} index={index + 2} name={category.displayName} pathname={pathForLevel(index + 1)} />
              )
            })}
          </>
        )}
      </ol>
    </nav>
  )
}
