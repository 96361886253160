export interface TableSkeletonProps {
  columnCount?: number
  rowCount?: number
}

export const TableSkeleton = ({ columnCount = 4, rowCount = 10 }: TableSkeletonProps) => (
  <table className='min-w-full divide-y divide-gray-300'>
    <thead className='bg-gray-50'>
      <tr>
        {Array.from({ length: columnCount }, (_, index) => (
          <th key={index} className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
            <div role='status' className='max-w-sm animate-pulse'>
              <div className='h-2.5 bg-gray-200 rounded-ful w-48 mb-4'></div>
              <span className='sr-only'>Loading...</span>
            </div>
          </th>
        ))}
      </tr>
    </thead>
    <tbody className='divide-y divide-gray-200 bg-gray-50'>
      {Array.from({ length: rowCount }, (_, index) => (
        <tr key={index}>
          <td key={index} className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
            <div role='status' className='max-w-sm animate-pulse'>
              <div className='h-2.5 bg-gray-200 rounded-full w-48 mb-4'></div>
              <span className='sr-only'>Loading...</span>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)
