import { createColumnHelper } from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import { PageHeading } from '../../../components/page-heading'
import { SectionHeadingWithNav } from '../../../components/section-heading-with-nav'
import { SummaryTable } from '../../../components/table'
import { ColumnHeader } from '../../../components/table/column-header'
import { useLocalTranslation } from '../../../hooks'
import { getLocalDate } from '../../../utils'
import { recordedDateFormat } from '../constants'
import { ReportSummary } from '../your-report'

export interface LearnerReportSummary extends ReportSummary {
  user: string
}

export function LearnerReport() {
  const t = useLocalTranslation()

  const columnHelper = createColumnHelper<LearnerReportSummary>()
  const columns = [
    columnHelper.accessor('simulation', {
      header: () => <ColumnHeader header='report.simulation' />,
      // No need to call useLocalTranslation since this value comes already translated from the API
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('session', {
      header: () => <ColumnHeader header='report.session' />,
      // No need to call useLocalTranslation since this value comes already translated from the API
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('user', {
      header: () => <ColumnHeader header='user' />,
      cell: (info) => t(info.getValue()),
    }),
    columnHelper.accessor('recorded', {
      header: () => <ColumnHeader header='report.recorded' />,
      cell: (info) => getLocalDate(info.getValue(), recordedDateFormat),
    }),
    columnHelper.accessor('analysisStatusSummary', {
      header: () => <ColumnHeader header='report.analysis' />,
      cell: (info) => {
        const style = 'w-5 h-5'
        switch (info.getValue()) {
          case 'completed':
            return <img src={`${process.env.PUBLIC_URL}/tick_32.png`} alt='Analysis completed' className={style} />
          case 'partial':
            return <img src={`${process.env.PUBLIC_URL}/partial_32.png`} alt='Partially analyised' className={style} />
          case 'not_started':
            return <img src={`${process.env.PUBLIC_URL}/dash_32.png`} alt='Not analyised' className={style} />
          default:
            return <span className={style}>&nbsp;&nbsp;-</span> // the spaces align the dash roughly with the images
        }
      },
    }),
    columnHelper.accessor('uuid', {
      header: () => <span></span>,
      cell: (info) =>
        info.row.original.hasReportDetails ? (
          <Link
            to={`../${info.getValue()}`}
            state={{ reportPath: '/report/learner', name: 'report.learnerReports' }}
            className='text-blue-500'
          >
            {t('report.viewReport')}
          </Link>
        ) : (
          '-'
        ),
      enableSorting: false,
    }),
  ]

  return (
    <>
      <PageHeading translationText='report.reports' />
      <SectionHeadingWithNav route='report' />
      <SummaryTable<LearnerReportSummary>
        columns={columns}
        defaultColumnSort={{
          id: 'id',
          desc: true,
        }}
        endpoint='/api/v1/report/learner'
        showGlobalFilter
        filterPlaceholderText='report.learnerReportsSearch'
      />
    </>
  )
}
