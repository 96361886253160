export const ConfirmationModalLoading = () => (
  <div className='flex space-x-2 animate-pulse'>
    {/**
     * This placeholder loader is required because the modal closes with a transition delay
     * and while its closing the licenseName and username values are lost. This causes the raw string
     * to be displayed for a moment before the modal closes.
     */}
    <div className='w-3 h-3 bg-gray-200 rounded-full'></div>
    <div className='w-3 h-3 bg-gray-200 rounded-full'></div>
    <div className='w-3 h-3 bg-gray-200 rounded-full'></div>
    <div className='w-3 h-3 bg-gray-200 rounded-full'></div>
  </div>
)
