import { PageLoader } from '../components/loaders/page'
import { useEffect } from 'react'
import { useAuth } from '../auth'
import { BRIGHTSPACE_HOSTNAME } from '../constants'
import { useNavigate } from 'react-router-dom'

export default function Logout() {
  const { isAuthenticated, isLoading, logout } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoading) return

    if (isAuthenticated) {
      logout({ redirectPath: '/logout' })
    } else {
      // The logout has already been done, now redirect to the Brightspace logout page
      if (BRIGHTSPACE_HOSTNAME) {
        window.location.replace(`https://${BRIGHTSPACE_HOSTNAME}/d2l/logout`)
      } else {
        console.error('No Brightspace address provided')
        navigate('/')
      }
    }
  }, [isAuthenticated, isLoading])

  return <PageLoader />
}
