import { PageHeading } from '../../../../../../components/page-heading'
import { ProductConfigurationDetailsForm } from '../../../product-configuration-details'

export function AddProductConfiguration() {
  return (
    <>
      <PageHeading translationText='addConfiguration' />
      <ProductConfigurationDetailsForm action='create' />
    </>
  )
}
