import { useSearchParams } from 'react-router-dom'
import CompanyLogo from '../../../../assets/logo.svg'
import { useLocalTranslation } from '../../../hooks'

// These errors are directly mapped to error handling in the server.
// So if these errors are updated, please make sure to update them in the server code as well.
// (Look for `lti_integration/views.py`)
const LAUNCH_ERROR_MESSAGE_MAPPING: Record<string, string> = {
  PERMISSION_DENIED: 'launch.permissionDeniedMessage',
  LTI_ERROR: 'launch.ltiErrorMessage',
}

export function LtiError() {
  const t = useLocalTranslation()
  const [searchParams] = useSearchParams()
  const error = searchParams.get('error')
  const errorMessage =
    error && error in LAUNCH_ERROR_MESSAGE_MAPPING
      ? LAUNCH_ERROR_MESSAGE_MAPPING[error]
      : LAUNCH_ERROR_MESSAGE_MAPPING.LTI_ERROR

  return (
    <div className='grid place-items-center bg-gray-50 px-6 py-24 sm:py-32 lg:px-8'>
      <div className='text-center'>
        <div className='flex h-24 shrink-0 justify-center '>
          <img className='h-20 w-auto fill-current' src={CompanyLogo} alt='Virtual Medical Coaching logo' />
        </div>
      </div>
      <p className='mt-6 text-base leading-7 text-gray-600'>{t(errorMessage)}</p>
      <p className='mt-6 text-base leading-7 text-gray-600'>{t('contactYourAdministrator')}</p>
    </div>
  )
}
