import { useLoaderData } from 'react-router-dom'
import { ApiResponse } from '../../api'
import { ErrorBanner } from '../../components/error-banner'
import { PageHeading } from '../../components/page-heading'
import { useLocalTranslation } from '../../hooks'
import { DownloadItem, DownloadItemProps } from './item'

export const Downloads = () => {
  const t = useLocalTranslation()
  const loaderData = useLoaderData() as ApiResponse<Array<DownloadItemProps>>

  return (
    <>
      <PageHeading translationText='downloads.heading' />
      {loaderData?.data && loaderData.data.length ? (
        <>
          <p className='text-xl leading-6 font-normal text-gray-500'>{t('downloads.subheading')}</p>
          <p className='text-lg leading-7 font-normal text-gray-500'>{t('downloads.description')}</p>
          <DownloadItem items={loaderData?.data} />
        </>
      ) : (
        <ErrorBanner heading='downloads.noDownloads' />
      )}
    </>
  )
}
