import { format, parse } from 'date-fns'
import { TFunction } from 'i18next'
import { format as formatTimezone } from 'date-fns-tz'
import { AxiosError, AxiosResponse } from 'axios'
import { DeleteUserResponse } from './hooks'

export const DATE_FORMAT = 'dd/MM/yyyy'

export const dateToString = (date: Date) => format(date, DATE_FORMAT)

export const stringToDate = (value: string) => (value ? parse(value, DATE_FORMAT, new Date()) : null)

export interface Product {
  id: number
  name: string
  description: string
  protocol: string
}

export const translateAndJoinList = (
  t: TFunction<'translation', undefined, 'translation'>,
  list: string[],
  separator = ' - ',
): string => {
  return list?.map((v): string => t(v)).join(separator)
}

export const getLocalDate = (dateTime: Date, dateFormat: string) => {
  // Converts a date from UTC to a local timezone based on browser settings
  const dateUTC = new Date(dateTime)
  // local timezone
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return formatTimezone(dateUTC, dateFormat, { timeZone })
}

type DeleteUserError = AxiosError<DeleteUserResponse> | undefined | null

interface GetDeleteUserSuccessMessages {
  isError: boolean
  axiosResponse: AxiosResponse<DeleteUserResponse> | undefined | null
  axiosError: DeleteUserError
}

export const getDeleteUserSuccessMessages = ({ isError, axiosResponse, axiosError }: GetDeleteUserSuccessMessages) => {
  // Deleting users has multiple steps and requires display of successfull and partial successfull deletion of the user.
  if (isError) return axiosError?.response?.data?.success ?? ''
  return axiosResponse?.data?.success ?? ''
}

export const getDeleteUserErrorMessages = (axiosError: DeleteUserError) => {
  return axiosError?.response?.data?.error ?? []
}
