import { PageHeading } from '../../../../../../components/page-heading'
import { LicenseBlockDetailsForm } from '../license-block-details-form'

export function NewLicenseBlock() {
  return (
    <>
      <PageHeading translationText='addLicense' />
      <LicenseBlockDetailsForm action='create' />
    </>
  )
}
