import { HelpText } from './componentry/helpText'
import { Title } from './componentry/title'

interface ReadOnlyFieldProps extends React.ComponentPropsWithoutRef<'input'> {
  title: string
  required?: boolean
  helpText?: string
}

export function ReadOnlyField({ title, required, helpText, ...props }: ReadOnlyFieldProps) {
  return (
    <div className='sm:col-span-4'>
      <Title id={props.id} title={title} required={required} />
      <input
        name={props.id}
        disabled={true}
        type='text'
        className='bg-gray-100 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 sm:text-sm sm:leading-6'
        {...props}
      />
      {helpText && <HelpText helpText={helpText} />}
    </div>
  )
}
