import { PropsWithChildren } from 'react'
import { Form as RouterForm, FormProps as RouterFormProps } from 'react-router-dom'

export interface FormElementProps {
  action: 'save' | 'create'
}

interface FormProps extends RouterFormProps {
  method: 'get' | 'post' | 'patch' | 'put'
}

export const Form = ({ method, children, ...props }: PropsWithChildren<FormProps>) => (
  <RouterForm
    method={method}
    className='container mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-6 lg:py-8 bg-inherit grid gap-y-8'
    {...props}
  >
    {children}
  </RouterForm>
)
