import { useLocalTranslation } from '../../../hooks'
import classNames from 'classnames'

type PageCountProps = {
  currentPageIndex: number
  totalPages: number
  localizationId: string
  center?: boolean
}

export function ReportPageCount(pageCountProps: PageCountProps) {
  const center = pageCountProps.center === undefined ? false : pageCountProps.center
  const t = useLocalTranslation()
  const baseStyle = 'font-bold text-xl flex'
  const style = classNames(baseStyle, { 'flex justify-center': center })

  return (
    <h3 className={style}>
      {t(pageCountProps.localizationId, {
        count: pageCountProps.currentPageIndex + 1,
        total: pageCountProps.totalPages,
      })}
    </h3>
  )
}
