import { Outlet, matchPath, useLocation, useParams } from 'react-router-dom'
import { PageHeadingLookup } from '../../../../components/page-heading-lookup'
import { SectionHeadingWithNav } from '../../../../components/section-heading-with-nav'

export interface CanDisplayRoutes {
  route: string
}

export interface Headings {
  route: string
  title: string
}

export const ProductWrapper = () => {
  const { pathname } = useLocation()
  const { productId } = useParams()

  const displaySectionHeadingWithNavRoutes: CanDisplayRoutes[] = [
    { route: `/admin/product/${productId}` },
    { route: `/admin/product/${productId}/download` },
  ]

  const isActiveSectionHeadingNav = displaySectionHeadingWithNavRoutes.some(({ route }) =>
    Boolean(matchPath(route, pathname)),
  )

  return (
    <>
      {isActiveSectionHeadingNav && (
        <>
          <PageHeadingLookup lookupField='product' lookupId={productId ? productId : ''} />
          <SectionHeadingWithNav
            route='product'
            dynamicRoutes={{
              product: [
                {
                  displayNameTranslation: 'productConfigurations',
                  href: `/admin/product/${productId}`,
                  isVisible: true,
                },
                {
                  displayNameTranslation: 'downloads.heading',
                  href: `/admin/product/${productId}/download`,
                  isVisible: true,
                },
              ],
            }}
          />
        </>
      )}
      <Outlet />
    </>
  )
}
