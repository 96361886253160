import { PropsWithChildren } from 'react'
import { useLocalTranslation } from '../hooks'

interface FormNestedSectionProps {
  title?: string
}

export const FormNestedSection = ({ title, children }: PropsWithChildren<FormNestedSectionProps>) => {
  const t = useLocalTranslation()
  return (
    <div className='max-w-2xl grid gap-4 bg-inherit'>
      {title && <h3 className='text-lg leading-6 font-medium'>{t(title)}</h3>}
      {children}
    </div>
  )
}
