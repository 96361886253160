import { AxiosError, AxiosInstance, HttpStatusCode } from 'axios'

interface DownloadFile {
  client: AxiosInstance
  endpoint: string
}

export const downloadFile = async ({ client, endpoint }: DownloadFile) => {
  try {
    executeFakeClick({ client, endpoint })
  } catch (error) {
    // Try second time in case auth token is currently being refreshed
    // Second retry is needed to handle rare scenario which may result in error on first attempt
    // to call Django API on very slow network connections
    if (error instanceof AxiosError) {
      if (error.response?.status) {
        if ([HttpStatusCode.Unauthorized].includes(error.response?.status)) {
          executeFakeClick({ client, endpoint })
        }
      }
    }
  }
}

const executeFakeClick = async (props: DownloadFile) => {
  const { data } = await props.client.get<{ url: string; originalName: string }>(props.endpoint)

  // Create a fake link on the page and click it.
  const fakeAnchorElement = document.createElement('a')
  fakeAnchorElement.href = '#fake'
  fakeAnchorElement.style.display = 'none'
  fakeAnchorElement.setAttribute('href', data.url)
  fakeAnchorElement.target = 'blank'
  fakeAnchorElement.rel = 'noopener noreferrer'
  fakeAnchorElement.download = data.originalName
  fakeAnchorElement.click()

  // Remove the element once its been used.
  setTimeout(() => fakeAnchorElement.remove(), 1000)
}
