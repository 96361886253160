import { HttpStatusCode } from 'axios'

import { LinkProps } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useLocalTranslation } from '../../hooks'

export interface ErrorComponentLinkProps extends LinkProps {
  label: string
}

export interface ErrorComponenProps {
  heading: string
  message: string
  statusCode?: HttpStatusCode
  primaryLink?: ErrorComponentLinkProps
  secondaryLink?: ErrorComponentLinkProps
}

export const ErrorComponent = ({
  heading,
  message,
  statusCode,
  primaryLink = { to: '/', label: 'goBackHome' },
  secondaryLink,
}: ErrorComponenProps) => {
  const t = useLocalTranslation()
  return (
    <main className='grid place-items-center px-6 py-24 sm:py-32 lg:px-8'>
      <div className='text-center'>
        {statusCode && <p className='text-base font-semibold text-blue-600'>{statusCode}</p>}
        <h1 className='mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl'>{t(heading)}</h1>
        <p className='mt-6 text-base leading-7 text-gray-600'>{t(message)}</p>
        <div className='mt-10 flex items-center justify-center gap-x-6'>
          <Link
            {...primaryLink}
            className='rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
          >
            {t(primaryLink.label)}
          </Link>
          {secondaryLink && (
            <Link {...secondaryLink} className='text-sm font-semibold text-gray-900'>
              {t(secondaryLink.label)}
            </Link>
          )}
        </div>
      </div>
    </main>
  )
}
