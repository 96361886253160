import { NavLink, useLocation, matchPath, useParams } from 'react-router-dom'
import { usePermission } from '../auth/permissions'
import { UserPermissions } from '../auth/types'
import classNames from 'classnames'
import { useLocalTranslation } from '../hooks'

export interface SectionHeading {
  displayNameTranslation: string
  href: string
  isVisible: boolean
}

interface SectionHeadings {
  [key: string]: SectionHeading[]
}

interface SectionHeadingProps {
  route: 'organisation' | 'administration' | 'simulation' | 'report' | 'product'
  dynamicRoutes?: SectionHeadings
}

export function SectionHeadingWithNav({ route, dynamicRoutes = {} }: SectionHeadingProps) {
  const { pathname } = useLocation()
  const { organisationId } = useParams()
  const t = useLocalTranslation()
  const canViewOrgReports = usePermission(UserPermissions.VIEW_ORG_REPORTS)

  const routes: SectionHeadings = {
    organisation: [
      { displayNameTranslation: 'organisationDetails', href: `/admin/organisation/${organisationId}`, isVisible: true },
      { displayNameTranslation: 'licenses', href: `/admin/organisation/${organisationId}/license`, isVisible: true },
      { displayNameTranslation: 'users', href: `/admin/organisation/${organisationId}/user`, isVisible: true },
    ],
    administration: [
      { displayNameTranslation: 'organisations', href: '/admin/organisation', isVisible: true },
      { displayNameTranslation: 'productConfigurations', href: '/admin/product', isVisible: true },
      { displayNameTranslation: 'notifications', href: './', isVisible: true },
    ],
    report: [
      { displayNameTranslation: 'report.yourReports', href: '/report', isVisible: true },
      { displayNameTranslation: 'report.learnerReports', href: '/report/learner', isVisible: canViewOrgReports },
    ],
    ...dynamicRoutes,
  }

  // Filter visible routes
  const sectionHeadings = routes[route].filter((sectionHeading) => sectionHeading.isVisible)

  return (
    <div className='sm:pb-0 border-b border-gray-200'>
      <div className='mt-3 sm:mt-4'>
        <div className='sm:hidden'>
          <label htmlFor='current-tab' className='sr-only'>
            Select a tab
          </label>
          <select
            id='current-tab'
            name='current-tab'
            className='block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm'
            defaultValue={
              sectionHeadings?.find((route: SectionHeading) => Boolean(matchPath(route.href, pathname)))
                ?.displayNameTranslation
            }
          >
            {sectionHeadings?.map((route: SectionHeading) => (
              <option key={route.displayNameTranslation}>{t(route.displayNameTranslation)}</option>
            ))}
          </select>
        </div>
        <div className='hidden sm:block'>
          <nav className='-mb-px flex space-x-8'>
            {sectionHeadings?.map((route: SectionHeading) => (
              <NavLink
                key={route.displayNameTranslation}
                to={route.href}
                className={() => {
                  const isActive = Boolean(matchPath(route.href, pathname))
                  return classNames(
                    'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium',
                    { 'border-blue-500 text-blue-600': isActive },
                    { 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700': !isActive },
                  )
                }}
              >
                {t(route.displayNameTranslation)}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}
