import { useLoaderData, useParams } from 'react-router-dom'
import { ApiResponse } from '../../../../api'
import { NotFound } from '../../../../components/error-component/not-found'
import { PageHeadingDynamicCategory } from '../../../../components/page-heading-dynamic-category'
import { PracticeViewCategoryTiles } from '../../../../components/practice-view-category'
import { useMemo } from 'react'

export interface PracticeViewCategory {
  id: number
  name: string
  displayName: string
  selectionMessage: string
  simulationConfiguration: string
  imagePath: string
  children: PracticeViewCategory[]
}

export interface ProductCategories {
  id: number
  name: string
  displayName: string
  categories: PracticeViewCategory[]
  selectionMessage: string
  protocol: string
}

export function getSelectedCategory(categories: PracticeViewCategory[], ids: number[]): PracticeViewCategory {
  const [currentLevel, ...remainingIds] = ids
  const selectedCategory = categories.filter(({ id }) => id === currentLevel)[0]

  return selectedCategory === undefined || remainingIds.length === 0
    ? selectedCategory
    : getSelectedCategory(selectedCategory.children, remainingIds)
}

export function PracticeViewCategorySection() {
  const loaderData = useLoaderData() as ApiResponse<ProductCategories>
  const parameters = useParams()

  const ids = useMemo(
    () =>
      (parameters['*'] ?? '')
        ?.split('/')
        .filter((v) => !!v)
        .map((id) => parseInt(id)),
    [parameters],
  )

  const category = useMemo(
    () => (ids.length === 0 ? null : getSelectedCategory(loaderData?.data?.categories ?? [], ids)),
    [loaderData?.data?.categories, ids],
  )

  if (ids.length !== 0 && !category) return <NotFound />

  const selectionMessage = (category ?? loaderData?.data)?.selectionMessage
  const children = category?.children ?? loaderData?.data?.categories ?? []

  return (
    <>
      <PageHeadingDynamicCategory
        translationTitle={category?.displayName ?? loaderData?.data?.name}
        translationSubheading={selectionMessage}
      />
      <PracticeViewCategoryTiles
        product={loaderData?.data?.name}
        productId={parameters['productId']}
        bodyArea={category?.name}
        bodyAreaName={category?.displayName}
        productProtocol={loaderData?.data?.protocol}
        categories={children}
      />
    </>
  )
}
