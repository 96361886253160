import { InformationCircleIcon } from '@heroicons/react/24/outline'

export const InfoBanner = ({ heading }: { heading: string }) => {
  return (
    <div className='rounded-md bg-blue-50 p-4'>
      <div className='flex'>
        <div className='flex-shrink-0'>
          <InformationCircleIcon className='h-5 w-5 text-blue-600' aria-hidden='true' />
        </div>
        <div className='ml-3'>
          <h3 className='text-sm font-medium text-blue-600'>{heading}</h3>
        </div>
      </div>
    </div>
  )
}
