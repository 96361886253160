import { createColumnHelper } from '@tanstack/react-table'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Pill } from '../../../../../components/pill'
import { NavigateButton } from '../../../../../components/buttons/navigate-button'

import { ColumnHeader } from '../../../../../components/table/column-header'
import { SummaryTable } from '../../../../../components/table'
import { useDocumentTitle, useLocalTranslation } from '../../../../../hooks'

export interface Licenses {
  id: number
  licenseName: string
  salesReference: string
  seatsUsed: string
  startDate: string
  expiryDate: string
  isActive: boolean
}

export function LicensesDashboard() {
  const t = useLocalTranslation()
  const { pathname } = useLocation()
  const { organisationId } = useParams()

  useDocumentTitle(t('Licenses'))

  const columnHelper = createColumnHelper<Licenses>()
  const columns = [
    columnHelper.accessor('licenseName', {
      header: () => <ColumnHeader header='licenseName' />,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('salesReference', {
      header: () => <ColumnHeader header='reference' />,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('seatsUsed', {
      header: () => <ColumnHeader header='seatsUsed' />,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('startDate', {
      header: () => <ColumnHeader header='startDate' />,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('expiryDate', {
      header: () => <ColumnHeader header='expires' />,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('isActive', {
      header: () => <ColumnHeader header='status' />,
      cell: (info) => <Pill isActive={info.getValue()} />,
    }),
    columnHelper.accessor('id', {
      header: () => <span></span>,
      cell: (info) => (
        <Link to={`${pathname}/${info.getValue()}`} className='text-blue-500'>
          {t('edit')}
        </Link>
      ),
      enableSorting: false,
    }),
  ]

  return (
    <>
      <div className='flex items-center justify-end gap-x-6'>
        <NavigateButton iconType='PlusIcon' translationText='addLicense' navigateTo={`${pathname}/new`} color='blue' />
      </div>
      <SummaryTable<Licenses>
        columns={columns}
        defaultColumnSort={{
          id: 'license_name',
          desc: false,
        }}
        endpoint={`/api/v1/organisation/${organisationId}/license-block`}
      />
    </>
  )
}
