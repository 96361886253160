import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { Link, useLocation } from 'react-router-dom'
import { Product } from '../../types'
import { useLocalTranslation } from '../../hooks'

interface ProductListProps {
  products: Product[]
}

export function ProductList({ products }: ProductListProps) {
  const { pathname } = useLocation()
  const t = useLocalTranslation()

  return (
    <ul
      role='list'
      className='divide-y divide-gray-100 overflow-hidden bg-gray-50 shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl'
    >
      {products.map(({ id, name, description }: Product) => (
        <li key={`product-${id}-${name}`}>
          <Link
            className='relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6'
            to={`${pathname}/${id}`}
          >
            <div className='flex gap-x-4'>
              <div className='min-w-0 flex-auto'>
                <p className='text-sm font-semibold leading-6 text-blue-600'>{t(name)}</p>
                <p className='mt-1 flex text-xs leading-5 text-gray-500'>{description}</p>
              </div>
            </div>
            <div className='flex items-center gap-x-4'>
              <ChevronRightIcon className='h-5 w-5 flex-none text-gray-400' aria-hidden='true' />
            </div>
          </Link>
        </li>
      ))}
    </ul>
  )
}
