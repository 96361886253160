import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { useAuth } from '../auth'
import { SERVER_ADDRESS } from '../constants'

export const useRequest = () => {
  const auth = useAuth()
  const [isLoading, setIsLoading] = useState(true)

  const client = useMemo(() => {
    setIsLoading(true)

    const localClient = axios.create({
      baseURL: SERVER_ADDRESS,
    })

    localClient.interceptors.response.use(
      (resp) => resp,
      (error) => Promise.reject(error),
    )
    return localClient
  }, [])

  useEffect(() => {
    if (!client || auth.isLoading) return

    client.defaults.headers.common = { ...client.defaults.headers.common, ...auth.authStrategy?.toHeader() }
    setIsLoading(false)
  }, [client, auth.authStrategy?.tokenIdentifier, auth.authStrategy?.token, auth.isLoading])

  return { client, isLoading }
}
