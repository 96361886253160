import { useCheckSimInstalled, useLocalTranslation } from '../../hooks'
import { useEffect, useRef } from 'react'
import { Trans } from 'react-i18next'

interface SimNotStartedProps {
  url?: string
  isLtiLaunch: boolean
}

export function SimNotStarted(props: SimNotStartedProps) {
  const t = useLocalTranslation()
  const launchLinkRef = useRef<HTMLAnchorElement | null>(null)

  useCheckSimInstalled()

  useEffect(() => {
    if (launchLinkRef.current) {
      launchLinkRef.current.click()
      // Reset the ref as we don't need it anymore
      launchLinkRef.current = null
    }
  }, [launchLinkRef])

  return (
    <>
      <p className='mt-6 text-base leading-7 text-gray-600'>{t('launch.instructionPrimary')}</p>
      <a href={props.url} ref={launchLinkRef}>
        <p className='mt-6 text-base leading-7 text-gray-600'>
          <Trans i18nKey='launch.instructionSecondary'>
            If you don’t see a pop-up, <span className='text-blue-500'>click here</span> to launch the simulation
            manually.
          </Trans>
        </p>
      </a>
    </>
  )
}
