import { useMatches, useLocation, Params } from 'react-router-dom'
import { GenericBreadcrumbs } from './generic-breadcrumb'
import { LookupFieldOptions } from '../form-fields/lookup'
import { DynamicBreadcrumbs } from './dynamic-breadcrumbs'
import { UserPermissions, User } from '../../auth/types'
import { useUser } from '../../auth'

export interface Handle {
  breadcrumb: {
    defaultName: string
    isParamRoute?: boolean
    lookupName?: string
    pathnameOveride?: string
    paramName?: string
    alwaysVisible?: boolean
    permission: UserPermissions[]
  }
}

export interface Data {
  data: LookupFieldOptions
}

// This type is not exported from react router and has been copied and
// manipulated to work with our 'handle' property
export interface Match {
  id: string
  pathname: string
  params: Params<string>
  data?: Data
  handle: Handle
}

const getPermittedMatches = (user: User | undefined, matches: Match[]) => {
  const userPermissions = user?.permissions

  return matches.filter((match) => {
    const breadcrumb = match.handle?.breadcrumb
    // if there is no permissions assigned exit early and display the breadcrumb
    // dynamic routes do not have a breadcrumb property which is why the route is returned
    if (!breadcrumb || !breadcrumb.permission) return true

    return breadcrumb.permission.some((permission) => userPermissions?.includes(permission))
  }) as Match[]
}

export function Breadcrumbs() {
  const [user] = useUser()

  const matches = useMatches() as Match[]
  const permittedMatches = getPermittedMatches(user, matches)
  const { pathname } = useLocation()
  const isDynamicRoute = pathname.includes('simulation')

  return isDynamicRoute ? (
    <DynamicBreadcrumbs dynamicRouteRoot='/simulation' matches={permittedMatches} />
  ) : (
    <GenericBreadcrumbs pathname={pathname} matches={permittedMatches} />
  )
}
