import { To } from 'react-router-dom'
import { NavigateButton } from '../../components/buttons/navigate-button'
import { useLocalTranslation } from '../../hooks'
import { getImage } from '../simulation-category'
import { useQuery } from '@tanstack/react-query'
import { useRequest } from '../../hooks/use-request'
import { useUser } from '../../auth'
import { LookupFieldOption } from '../form-fields/lookup'

interface EntitlementMode {
  id: string
  mode: LookupFieldOption
}

interface SimLaunchActionProps {
  name?: string
  displayName?: string
  simulationConfiguration: string | null
  productProtocol: string | null
  productId?: string | null
  navigateTo?: To
  imageDir?: string
}

const canUseLaunchMode = (entitlementModes: EntitlementMode[] | undefined, modeDescription: string) => {
  return entitlementModes && entitlementModes.some((em) => em.mode.description === modeDescription)
}

export function SimLaunchActions({
  name,
  displayName,
  simulationConfiguration: configuration,
  productProtocol: protocol,
  productId,
  navigateTo = '/simulation/launch',
  imageDir,
}: SimLaunchActionProps) {
  const [user] = useUser()
  const { client } = useRequest()
  const t = useLocalTranslation()

  const { data } = useQuery({
    queryKey: ['entitlementModes'],
    queryFn: async () => {
      const response = client.get<Array<EntitlementMode>>(`/api/v1/user/${user?.id}/entitlement-mode/${productId}`)
      return (await response).data
    },
    enabled: !!user,
  })

  return (
    <div className='flex flex-col bg-gray-50 rounded-b-2xl shadow-xl gap-y-2'>
      {imageDir && (
        <img className='rounded-t-2xl cover object-center h-full' src={getImage(`${imageDir}/${name}.jpg`)} alt='' />
      )}
      <div className='flex flex-col gap-y-2 p-4'>
        {name && <p className='text-xl font-semibold leading-7 text-gray-900'>{displayName}</p>}
        <div className='flex flex-col gap-y-2 max-w-fit'>
          {canUseLaunchMode(data, 'VR') && (
            <NavigateButton
              translationText='launchVRSimulation'
              navigateTo={navigateTo}
              color='blue'
              state={{ configuration, protocol, productId, mode: 'VR' }}
            />
          )}
          {canUseLaunchMode(data, 'Desktop') && (
            <NavigateButton
              translationText='launch2DSimulation'
              navigateTo={navigateTo}
              state={{ configuration, protocol, productId, mode: 'Desktop' }}
              color='light-blue'
            />
          )}
        </div>
      </div>
    </div>
  )
}
