import { useDocumentTitle, useLocalTranslation } from '../hooks'

interface PageHeadingDynamicCategoryProps {
  translationTitle: string
  translationSubheading?: string
}

export function PageHeadingDynamicCategory({
  translationTitle,
  translationSubheading,
}: PageHeadingDynamicCategoryProps) {
  const t = useLocalTranslation()

  useDocumentTitle(t(translationTitle))

  return (
    <>
      <h3 className='mt-12 font-bold leading-9 text-gray-900 text-3xl'>{t(translationTitle)}</h3>
      {translationSubheading && (
        <p className='text-xl leading-7 font-normal text-gray-500'>{t(translationSubheading)}</p>
      )}
    </>
  )
}
