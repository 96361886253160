import { usePermission } from '../auth/permissions'
import { UserPermissions } from '../auth/types'
import { MailtoButton } from '../components/buttons/mailto-button'
import { NavigateButton } from '../components/buttons/navigate-button'
import { Divider } from '../components/divider'
import { PageHeading } from '../components/page-heading'
import { useLocalTranslation } from '../hooks'

export function SupportCentre() {
  const t = useLocalTranslation()
  const canAccessVmcSupport = usePermission(UserPermissions.CONTACT_VMC_SUPPORT)

  return (
    <>
      <PageHeading translationText='supportCentre.heading' subheadingTranslationText='supportCentre.description' />
      <div className='flex items-center justify-start py-4'>
        <NavigateButton
          translationText='supportCentre.buttonFAQ'
          navigateTo='https://help.virtualmedicalcoaching.com/'
          target='_blank'
          color='blue'
        />
      </div>
      {canAccessVmcSupport && (
        <>
          <Divider />
          <div className='grid min-h-full place-items-left bg-gray-50 w-fit p-8 mt-4'>
            <span className='text-lg leading-6 font-medium text-gray-900'>
              {t('supportCentre.technicalSupportTeamName')}
            </span>
            <span className='text-base leading-6 font-normal text-gray-500 pt-2'>
              <MailtoButton
                emailAddress={t('supportCentre.technicalSupportEmailAddress')}
                label={t('supportCentre.technicalSupportEmailAddress')}
              />
            </span>
          </div>
        </>
      )}
    </>
  )
}
