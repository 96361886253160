import { useEffect, useRef, useState } from 'react'
import { useActionData, useNavigate, useParams } from 'react-router-dom'
import { ApiResponse } from '../../../../../../api'
import { useUser } from '../../../../../../auth'
import { NavigateButton } from '../../../../../../components/buttons/navigate-button'
import { SubmitButton } from '../../../../../../components/buttons/submit-button'
import { Divider } from '../../../../../../components/divider'
import { FileUpload } from '../../../../../../components/file-upload'
import { downloadFile } from '../../../../../../components/file-upload/download'
import { FileManager } from '../../../../../../components/file-upload/manager'
import { MimeTypes, NewFile, NewOrUploadedFile, UploadedFile } from '../../../../../../components/file-upload/types'
import {
  CSV_FILE_UPLOAD_VALIDATOR_PARAMS,
  buildDefaultValidators,
} from '../../../../../../components/file-upload/validators'
import { Form } from '../../../../../../components/form'
import { LookupField, LookupFieldOption, LookupFieldOptions } from '../../../../../../components/form-fields/lookup'
import { ReadOnlyLookupField } from '../../../../../../components/form-fields/read-only-lookup'
import { FormNestedSection } from '../../../../../../components/form-nested-group'
import { FormWrapper } from '../../../../../../components/form-wrapper'
import { InfoBanner } from '../../../../../../components/info-banner'
import { PageHeading } from '../../../../../../components/page-heading'
import { useLocalTranslation } from '../../../../../../hooks'
import { useRequest } from '../../../../../../hooks/use-request'
import { LocationStateProps } from '../../../../../types'
import { ConfirmationModal } from '../../../../../../components/confirmation-modal'

export type UserData = {
  organisationId: string
  fileId: string
  licenseBlockId: LookupFieldOption
  roleId: LookupFieldOption
}

export const BulkAddUsersForm = () => {
  const actionData = useActionData() as ApiResponse<LocationStateProps>
  const { organisationId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const hiddenFileRef = useRef<HTMLInputElement>(null)
  const submitBtnRef = useRef<HTMLButtonElement>(null)
  const [uploadedFile, setUploadedFile] = useState<NewOrUploadedFile | null>()
  const [isAdminRole, setIsAdminRole] = useState<boolean | null>(null)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const { client } = useRequest()
  const t = useLocalTranslation()
  const navigate = useNavigate()
  const [user, userIsLoading] = useUser()

  useEffect(() => {
    if (!isLoading && user?.isBrightspaceAuthRequired) {
      // Redirect to Brightspace auth if the user is not authenticated with it
      window.location.href = '/api/brightspace-auth?redirect_path=' + window.location.pathname
    } else if (!isLoading && user?.brightspaceUserMismatch) {
      navigate('/brightspace-user-mismatch', { state: { from: window.location.pathname } })
    }
  }, [user?.isBrightspaceAuthRequired, user?.brightspaceUserMismatch, userIsLoading])

  const onFileUploaded = (file: UploadedFile) => {
    if (hiddenFileRef.current) {
      hiddenFileRef.current.value = file.id
    }
    setUploadedFile(file)
    setIsLoading(false)
  }

  const onFileUploading = (file: NewFile) => {
    setUploadedFile(file)
    setIsLoading(true)
  }

  const onFileUploadFailed = () => {
    setUploadedFile(null)
    setIsLoading(false)
  }

  const handleSubmit = () => {
    setShowConfirmationDialog(!!isAdminRole)
    if (!isAdminRole) {
      submitBtnRef.current?.click()
    }
  }

  const handleConfirm = () => {
    setShowConfirmationDialog(false)
    submitBtnRef.current?.click()
  }

  const handleRoleChange: (event: React.ChangeEvent<HTMLSelectElement>, options?: LookupFieldOptions) => void = (
    event,
    options,
  ) => {
    const sysAdminOption = options?.find((option) => (option.description as string).toLowerCase() === 'sys admin')
    setIsAdminRole(sysAdminOption?.id == event.target.value)
  }

  return (
    <>
      <PageHeading translationText='addUsers' />
      <InfoBanner heading={t('bulkUploadFormDelayWarning')} />
      <ConfirmationModal
        title={t('addAdminUserModal.title')}
        body={t('addAdminUserModal.message')}
        open={showConfirmationDialog}
        onConfirm={handleConfirm}
        onCancel={() => setShowConfirmationDialog(false)}
        confirmActionLabel={t('addAdminUserModal.confirmActionLabel')}
      />
      <FormWrapper
        isError={actionData?.isError}
        error={actionData?.error}
        isSuccess={actionData?.isSuccess}
        successRedirectTo={`/admin/organisation/${organisationId}/user`}
        locationState={actionData?.data}
      >
        <Form method='post'>
          <FormNestedSection title='userDetails'>
            <input type='hidden' name='organisationId' value={organisationId} />
            <input type='hidden' name='fileId' ref={hiddenFileRef} />
            <ReadOnlyLookupField title='organisation' lookupField='organisation' lookupId={organisationId ?? ''} />
            <FileUpload
              title='usersCsvFile'
              allowedFileTypes={[MimeTypes.CSV]}
              onFileUploading={onFileUploading}
              onFileUploaded={onFileUploaded}
              onFileUploadFailed={onFileUploadFailed}
              fileValidators={buildDefaultValidators(CSV_FILE_UPLOAD_VALIDATOR_PARAMS)}
              endpoint={`/api/v1/file/upload/user`}
              disabled={isLoading}
              hasError={!!actionData?.error}
              errorMessages={actionData?.error?.file}
              required
            />
            {uploadedFile && (
              <FileManager
                file={uploadedFile}
                downloadAction={async (file: UploadedFile) => {
                  downloadFile({
                    client,
                    endpoint: `/api/v1/file/${file.id}/download/user`,
                  })
                }}
              />
            )}

            <LookupField
              id='licenseBlockId'
              lookupField='licenses'
              endpoint={`/api/v1/lookup?field=organisation_licenses&organisation=${organisationId}`}
              title='licenseName'
              required
              hasError={!!actionData?.error}
              errorMessages={actionData?.error?.license}
            />

            <LookupField
              id='roleId'
              lookupField='roles'
              title='role'
              required
              hasError={!!actionData?.error}
              errorMessages={actionData?.error?.role}
              onChangeCallback={handleRoleChange}
            />
          </FormNestedSection>

          <Divider />

          <div className='flex items-center justify-end gap-x-6'>
            <NavigateButton translationText='cancel' navigateTo={`..`} color='white' />
            {/* This is the button visible in the UI (using SubmitButton component, but with type overridden to be a regular button) */}
            <SubmitButton translationText='addUsers' type='button' onClick={handleSubmit} disabled={isLoading} />
            {/* This is a hidden button which actually executes form submission, which may be after confirmation modal is used */}
            <SubmitButton hidden ref={submitBtnRef} translationText='addUsers' disabled={isLoading} />
          </div>
        </Form>
      </FormWrapper>
    </>
  )
}
