import { useQuery } from '@tanstack/react-query'
import { useRequest } from '../hooks/use-request'
import { LookupFieldOption, LookupFieldOptions } from './form-fields/lookup'
import { useMemo, useState } from 'react'
import { useDocumentTitle, useLocalTranslation } from '../hooks'

interface PageHeadingProps {
  lookupId: string
  lookupField: string
}

export function PageHeadingLookup({ lookupField, lookupId }: PageHeadingProps) {
  const endpoint = `/api/v1/lookup?field=${lookupField}`
  const { client } = useRequest()
  const [lookupOptions, setLookupOptions] = useState<LookupFieldOptions | []>([])
  const t = useLocalTranslation()

  useQuery({
    queryKey: [lookupField],
    queryFn: async () => client.get<LookupFieldOptions>(endpoint),
    onSuccess: async ({ data }) => {
      setLookupOptions(data)
    },
  })

  const displayValue = lookupOptions?.find(({ id }: LookupFieldOption) => id == lookupId)

  const title = useMemo(() => {
    if (!displayValue) return undefined
    return t(displayValue.description)
  }, [displayValue?.description])

  useDocumentTitle(title)

  if (!displayValue) return null

  return <h3 className='font-semibold leading-6 text-gray-900 text-3xl'>{title}</h3>
}
