// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FileMetadataCallback = (file: any, isEditable: boolean, validationErrors: any) => JSX.Element

export enum FileState {
  UPLOADING = 'uploading',
  UPLOADED = 'uploaded',
  SAVED = 'saved',
}

export interface BaseFile {
  id: string
  state: FileState
  originalName: string
  size: number
  createdTimestamp: string
}

export interface NewFile extends BaseFile {
  state: FileState.UPLOADING
  file: File
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any
  abortController: AbortController
}

export interface UploadedFile extends BaseFile {
  state: FileState.UPLOADED
  uuid: string
  url?: string
}

export type NewOrUploadedFile = NewFile | UploadedFile

export type FileSaveActionCallback = (file: NewFile) => Promise<Omit<UploadedFile, 'state'>>
export type FileUploadingCallback = (file: NewFile) => void
export type FileUploadedCallback = (file: UploadedFile, temp_id: string) => void
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FileUploadFailedCallback = (file: NewFile, error: any) => void
export type DownloadAction = (file: UploadedFile) => void

export interface FileErrorResponse {
  file: string[]
}

export interface FileUploadProps {
  fileRequirementsText?: string
  onFileUploading?: FileUploadingCallback
  onFileUploaded?: FileUploadedCallback
  onFileUploadFailed?: FileUploadFailedCallback
  fileValidators?: FileValidator[]
  allowedFileTypes?: string[]
  endpoint: string
  disabled?: boolean
  title?: string
  required?: boolean
  hasError?: boolean
  errorMessages?: Array<string>
}

export type RemoveFileCallback = (file: NewOrUploadedFile) => void

export enum MimeTypes {
  // Image formats (TBC once simulation data is captured)
  JPG = 'image/jpeg',
  PNG = 'image/png',

  // Document formats
  CSV = 'text/csv',

  // JSON file
  JSON = 'application/json',
}

export interface FileValidationResult {
  isValid: boolean
  error?: string
}

export type FileValidator = (file: File) => FileValidationResult

export interface DownloadProps {
  file: NewOrUploadedFile
  isDisabled?: boolean
  isLoading?: boolean
  downloadAction: DownloadAction | null
}
